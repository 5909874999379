import React from 'react';

import { Link } from 'react-router-dom';
import { css } from '@emotion/css';
import format from 'date-fns/format';
import { useQuery } from 'react-query';

import useDikastisRouting from 'router/useDikastisRouting';
import useMockTask from 'hooks/mock/useMockTask';

import DktText from 'shared/DktText';
import EditIcon from 'shared/icons/EditIcon';
import FlexLayout from 'shared/FlexLayout';
import ProblemBody from 'problems/shared/ProblemBody';
import ProblemList from 'problems/shared/ProblemList';
import RoundIcons from 'shared/icons/statusIcons/RoundIcons';

const containerStyle = css`
  margin: 80px auto;
  max-width: 1300px;
`;
const descriptionStyle = css`
  color: #EEBF4D;
  margin-bottom: 20px;
  margin-left: 180px;
`;
const problemListStyle = css`
  width: 70%;
  margin-left: 180px;
  margin-right: 74px;
`;
const titleStyle = css`
  margin-left: 180px;
`;
const editIconStyle = css` 
  margin-left: 20px;
`;

const ExpandableComponent = ({ row }) => <ProblemBody id={row.original.id} />;

export default function TeamTask() {
  const { tableColumns } = useMockTask();

  const { params } = useDikastisRouting();
  const { taskId, teamId } = params;
  const { data: task } = useQuery(`/tasks/${taskId}`);
  const problems = task.problems.map(({ order, problem, weight }) => ({
    ...problem,
    status: <RoundIcons status={problem.status} />,
    title: `${String.fromCharCode(65 + order)}. ${problem.title}`,
    weight,
  }));

  const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy - HH:mm');

  return (
    <div className={containerStyle}>
      <FlexLayout alignItems="flex-end">
        <DktText holder="h2" style={titleStyle}>{task.title}</DktText>
        {task.role !== 'MEMBER' && (
          <Link to={`/teams/${teamId}/${taskId}/edit`}>
            <EditIcon className={editIconStyle} />
          </Link>
        )}
      </FlexLayout>
      <DktText holder="h4" style={titleStyle}>{`Start: ${formatDate(task.beginTime)}`}</DktText>
      <DktText holder="h4" style={titleStyle}>{`End: ${formatDate(task.endTime)}`}</DktText>
      <DktText holder="p" style={descriptionStyle}>{task.description}</DktText>
      <FlexLayout>
        <ProblemList
          ExpandableComponent={ExpandableComponent}
          columns={tableColumns}
          problems={problems}
          tableStyle={problemListStyle}
        />
      </FlexLayout>
    </div>
  );
}
