import React from 'react';

import { css } from '@emotion/css';

import { Link } from 'react-router-dom';

import FlexLayout from 'shared/FlexLayout';

const containerStyle = css`
  height: 100px;
`;
const selectorStyle = css`
  background: #F6BE29;
  border-radius: 0px 10px 10px 0px;
  width: 12px;
  height: 100%;
`;
const dummyStyle = css`
  width: 12px;
`;

const DummyDiv = () => (<div className={dummyStyle} />);

export default function NavbarMenuItem({ Icon, path, selected }) {
  return (
    <FlexLayout alignItems="center" justifyContent="space-between" style={containerStyle}>
      {selected ? <div className={selectorStyle} /> : <DummyDiv /> }
      <Link to={path}>
        <Icon fill={selected ? '#F6BE29' : '#909090'} />
      </Link>
      <DummyDiv />
    </FlexLayout>
  );
}
