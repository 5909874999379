import React from 'react';

import { Route } from 'react-router-dom';

import { Path } from 'router/routing';

import DktRouter from 'router/DktRouter';
import TaskCreationContainer from 'teams/task/TaskCreationContainer';
import TaskEditContainer from 'teams/task/TaskEditContainer';
import Team from 'teams/shared/Team';
import TeamCreationContainer from 'teams/form/TeamCreationContainer';
import TeamEditContainer from 'teams/form/TeamEditContainer';
import TeamTask from 'teams/task/TeamTask';
import TeamTaskFeedback from 'teams/task/feedback/TeamTaskFeedback';
import TeamsHome from 'teams/home/TeamsHome';

export default function TeamRouter() {
  return (
    <DktRouter>
      <Route exact component={TeamsHome} path={Path.TEAMS_HOME} />
      <Route exact component={TeamCreationContainer} path={Path.TEAMS_NEW} />
      <Route exact component={Team} path={Path.TEAMS_TEAM} />
      <Route exact component={TeamEditContainer} path={Path.TEAMS_TEAM_EDIT} />
      <Route exact component={TaskCreationContainer} path={Path.TEAMS_TEAM_NEW_TASK} />
      <Route exact component={TaskEditContainer} path={Path.TEAMS_TEAM_TASK_EDIT} />
      <Route exact component={TeamTask} path={Path.TEAMS_TEAM_TASK} />
      <Route exact component={TeamTaskFeedback} path={Path.TEAMS_TEAM_TASK_FEEDBACK} />
    </DktRouter>
  );
}
