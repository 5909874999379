import React from 'react';

import { css, cx } from '@emotion/css';

import DktTable from 'shared/table/DktTable';
import DktText from 'shared/DktText';
import ProblemDifficulty from 'problems/shared/ProblemDifficulty';

const tableStyleBase = css`
  width: 100%;
  padding: 20px 0;
`;

function ProblemHeader({ title, topic }) {
  return (
    <div>
      <DktText holder="h3">{title}</DktText>
      <DktText holder="p">{topic}</DktText>
    </div>
  );
}

export default function ProblemList({
  ExpandableComponent,
  columns,
  problems,
  rowOnClick,
  tableStyle,
}) {
  const data = problems.map((problem) => ({
    ...problem,
    difficulty: <ProblemDifficulty difficulty={problem.difficulty} />,
    title: <ProblemHeader title={problem.title} topic={problem.topic} />,
    weight: problem.weight,
  }));

  return (
    <DktTable
      ExpandableComponent={ExpandableComponent}
      cardStyle={cx(tableStyleBase, tableStyle)}
      columns={columns}
      data={data}
      rowOnClick={rowOnClick}
    />
  );
}
