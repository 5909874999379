import React from 'react';

import { Form, Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { css } from '@emotion/css';
import format from 'date-fns/format';

import { dikastisApi } from 'dikastis-api';
import useDikastisRouting from 'router/useDikastisRouting';

import DktButton from 'shared/DktButton';
import DktFormField from 'shared/form/DktFormField';
import DktText from 'shared/DktText';
import FeedbackOverview from 'shared/feedbacks/FeedbackOverview';
import FlexLayout from 'shared/FlexLayout';
import ProblemBody from 'problems/shared/ProblemBody';
import ProblemList from 'problems/shared/ProblemList';
import RoundIcons from 'shared/icons/statusIcons/RoundIcons';

const containerStyle = css`
  margin: 68px auto 0;
  max-width: 1600px;
`;
const taskStyle = css`
  :last-child {
    margin-left: 20px;
  }
`;
const flexStyle = css`
  margin-top: 20px;
`;
const notReadyStyle = css`
  height: 100%;
`;

const columns = [
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Difficulty',
    accessor: 'difficulty',
  },
  {
    Header: 'Weight',
    accessor: 'weight',
  },
];

export default function DikastisOrganizationTaskPerson() {
  const { params } = useDikastisRouting();
  const { taskId } = params;
  const { data: task } = useQuery(`/tasks/${taskId}`);
  const { data: person } = useQuery('/people/');
  const { data: feedback, refetch } = useQuery(
    `/feedbacks/tasks/${taskId}/${person.id}`,
    async () => {
      const response = await dikastisApi.get(`/feedbacks/tasks/${taskId}/${person.id}`).catch((err) => err.response);
      if (response.status === 404) {
        return { notFound: true };
      }
      return response.data;
    },
  );

  if (feedback.notFound || feedback.status !== 'SENT') {
    return (
      <FlexLayout alignItems="center" justifyContent="center" style={notReadyStyle}>
        <DktText holder="h3">Your feedback is not ready yet, come back later :&#41;</DktText>
      </FlexLayout>
    );
  }

  const problems = task.problems.map(({ order, problem, weight }) => ({
    ...problem,
    status: <RoundIcons status={problem.status} />,
    title: `${String.fromCharCode(65 + order)}. ${problem.title}`,
    weight,
  }));

  const sent = feedback.status === 'SENT';

  const reply = useMutation(
    (feedbackReply) => dikastisApi.patch(`/feedbacks/${feedback.id}/reply`, feedbackReply),
    { onSuccess: () => { refetch(); } },
  );

  const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy - HH:mm');

  const ExpandableComponent = ({ row }) => <ProblemBody id={row.original.id} />;

  return (
    <div className={containerStyle}>
      <Formik
        initialValues={{
          ...feedback,
          reply: feedback.reply || '',
        }}
        validateOnChange={false}
        onSubmit={(values) => {
          const feedbackReply = {
            reply: values.reply,
          };
          reply.mutate(feedbackReply);
        }}
      >
        <Form>
          <DktText holder="h2">{task.title}</DktText>
          <FlexLayout>
            <DktText holder="p" style={taskStyle}>{`Start: ${formatDate(task.beginTime)}`}</DktText>
            <DktText holder="p" style={taskStyle}>{`End: ${formatDate(task.endTime)}`}</DktText>
          </FlexLayout>
          <FlexLayout justifyContent="space-between" style={flexStyle}>
            <div className={css`width:100%`}>
              <ProblemList
                ExpandableComponent={ExpandableComponent}
                columns={columns}
                problems={problems}
              />
            </div>
            <div className={css`width:100%; margin-left:32px`}>
              {sent && (<FeedbackOverview feedback={feedback} />)}
              {sent && !feedback.reply && <DktFormField name="reply" placeholder="Reply to the person that wrote your feedback" title="Reply" type="preview" />}
              {sent && !feedback.reply && <DktButton submit style={css`margin:4px`}>Send</DktButton>}
            </div>
          </FlexLayout>
        </Form>
      </Formik>
    </div>
  );
}
