import React from 'react';

import { css, cx } from '@emotion/css';

import { Link } from 'react-router-dom';

import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

const textStyle = css`
  margin: 4px 32px;
`;
const hrStyle = css`
  background-color: #172B4D;
  border: 2px solid #172B4D;
  border-radius: 10px;
  height: 2px;
  margin: 0 32px;
  width: 100%;
`;

// this is not css since Link expects an obj
const linkStyle = {
  textDecoration: 'none',
};

export default function TabBarItem({ link, name, selected }) {
  return (
    <Link style={linkStyle} to={link}>
      <FlexLayout alignItems="center" flexDirection="column">
        <DktText holder="h4" style={textStyle}>{name}</DktText>
        {selected && <hr className={cx(hrStyle)} /> }
      </FlexLayout>
    </Link>
  );
}
