import React, { useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { css } from '@emotion/css';
import { useQuery } from 'react-query';

import DktButton from 'shared/DktButton';
import DktSearchBar from 'shared/DktSearchBar';
import DktText from 'shared/DktText';
import EditIcon from 'shared/icons/EditIcon';
import FlexLayout from 'shared/FlexLayout';
import MemberList from 'shared/members/MemberList';
import TaskList from 'shared/task/TaskList';

const containerStyle = css`
  max-width: 1200px;
  margin: auto;
`;
const buttonStyle = css`
  height: fit-content;
  margin-top: 36px;
`;
const editIconStyle = css` 
  margin-left: 79%;
  margin-right: 20px;
`;
const titleStyle = css`
  margin-top: 56px;
`;
const descriptionStyle = css`
  color: #EEBF4D;
`;
const listsStyle = css`
  margin-top: 20px;
`;

const taskLinkTo = (organizationId, teamId, taskId) => `${organizationId
  ? `/organizations/${organizationId}`
  : '/teams'
}/${teamId}/${taskId}`;

export default function Team() {
  const { organizationId, teamId } = useParams();
  const { data: team } = useQuery(`/teams/${teamId}`);
  const [search, onSearch] = useState('');
  const tasks = team.tasks.filter((task) => (
    task.title.toLowerCase().includes(search.toLowerCase())
  )).map((task) => (
    { ...task, link: taskLinkTo(organizationId, teamId, task.id) }
  ));

  return (
    <div className={containerStyle}>
      <FlexLayout alignItems="center">
        <DktSearchBar onKeyDown={onSearch} />
        <DktButton negative href={`/teams/${teamId}/new-task`} style={buttonStyle}>New task</DktButton>
      </FlexLayout>
      <FlexLayout justifyContent="space-between" style={titleStyle}>
        <div>
          <DktText holder="h2">{team.name}</DktText>
          <DktText holder="p">{`ID: ${team.id}`}</DktText>
          <DktText holder="p" style={descriptionStyle}>{team.description}</DktText>
        </div>
        {team.role !== 'MEMBER' && (
          <Link to={`/teams/${teamId}/edit`}>
            <EditIcon className={editIconStyle} />
          </Link>
        )}
      </FlexLayout>
      <FlexLayout justifyContent="space-between" style={listsStyle}>
        <TaskList tasks={tasks} />
        <MemberList members={team.members} />
      </FlexLayout>
    </div>
  );
}
