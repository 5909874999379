import React from 'react';

import { useParams } from 'react-router-dom';

import ProblemBody from 'problems/shared/ProblemBody';

export default function ProblemBodyContainer() {
  const { problemId } = useParams();

  return (
    <ProblemBody id={problemId} />
  );
}
