import React from 'react';

import { css } from '@emotion/css';
import { useField } from 'formik';
import { useMutation } from 'react-query';

import { dikastisApi } from 'dikastis-api';
import useSubmission from 'hooks/useSubmission';
import { useToast } from 'toast/toast-context';

import DktAce from 'shared/DktAce';
import DktButton from 'shared/DktButton';
import DktCard from 'shared/DktCard';
import DktFormField from 'shared/form/DktFormField';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

const editorStyle = css`
  margin: 20px auto;
  padding: 0;
  box-sizing: border-box;
  > div > div {
    border-radius: 10px;
  }
`;
const saveButtonStyle = css`
  width: fit-content;
  height: fit-content;
`;
const judgingCodeExplanation = css`
margin-top: 8px;
`;
const languages = {
  HASKELL: 'Haskell',
  JAVA8: 'Java 8',
  PYTHON3: 'Python 3',
};

export default function DynamicProblemForm({ problem }) {
  const { addToast } = useToast();
  const { fetchSubmissionResult } = useSubmission();
  const { id } = problem;
  const [{ value: language }] = useField('judgingLanguage');
  const [{ value: code },, { setValue: setJudgingCode }] = useField('judgingCode');

  const saveJudgingCode = useMutation(
    () => dikastisApi.post('/submissions', {
      code, language, problemId: id, type: 'JUDGING_CODE',
    }),
    {
      onSuccess: ({ data }) => {
        const submissionId = data.id;
        fetchSubmissionResult(submissionId).then((submission) => {
          if (!submission) {
            addToast(500);
            return;
          }
          addToast(submission.status);
        });
      },
    },
  );

  return (
    <>
      <FlexLayout alignItems="flex-end">
        <DktFormField as="select" name="judgingLanguage" title="Judging language">
          {Object.entries(languages).map(([value, display]) => (
            <option key={value} value={value}>{display}</option>
          ))}
        </DktFormField>
        <DktButton style={saveButtonStyle} onClick={saveJudgingCode.mutate}>Save code</DktButton>
      </FlexLayout>
      <DktText style={judgingCodeExplanation}>
        {'Test case input will be in "input"'
      + ' file and user output in "user_output".\n'
      + '1 should be printed to std out for accepted answers, 0 otherwise.'}
      </DktText>
      <DktCard style={editorStyle}>
        <DktAce code={code} id="problem-editor" onChange={setJudgingCode} />
      </DktCard>
    </>
  );
}
