import React from 'react';

import { useMutation } from 'react-query';

import { dikastisApi } from 'dikastis-api';
import { useHistory } from 'react-router-dom';

import TaskForm from 'teams/task/form/TaskForm';

export default function TaskCreationContainer() {
  const history = useHistory();
  const createTask = useMutation(
    (taskForm) => dikastisApi.post('/tasks', taskForm),
    {
      onSuccess: ({ data: task }, taskForm) => {
        history.push(`/teams/${taskForm.teamId}/${task.id}`);
      },
    },
  );

  const onSubmit = (newTask) => {
    createTask.mutate(newTask);
  };

  return <TaskForm onSubmit={onSubmit} />;
}
