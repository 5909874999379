import React, { Suspense, useEffect, useState } from 'react';

import { css } from '@emotion/css';
import isBefore from 'date-fns/isBefore';

import DktCard from 'shared/DktCard';
import DktLoader from 'shared/DktLoader';
import DktText from 'shared/DktText';
import StatusList from 'organizations/person/StatusList';
import Submission from 'organizations/person/Submission';

const cardStyle = css`
  margin-left: 32px;
  width: 100%;
  max-width: 60%;
`;
const cardStyle2 = css`
  margin-left: 32px;
`;
const containerStyle = css`
  border-bottom: 1px solid #F8B917;
`;

export default function SubmissionList({
  problem, submissions, task, width,
}) {
  const [submission, selectSubmission] = useState(undefined);
  const taskEndDate = new Date(task.endTime);
  const submissionsWithStatus = submissions.map((s) => {
    const submissionDate = new Date(s.date);
    if (isBefore(taskEndDate, submissionDate)) {
      return { ...s, status: `${s.status}_LATE` };
    }
    return s;
  });

  useEffect(() => {
    selectSubmission(undefined);
  }, [problem]);

  return (
    <DktCard style={width ? cardStyle2 : cardStyle}>
      <div className={containerStyle}>
        <DktText holder="p">
          <strong>Submissions for: </strong>
          {problem ? problem.title : ''}
        </DktText>
        <StatusList
          list={submissionsWithStatus}
          select={selectSubmission}
          selectedItem={submission}
        />
      </div>
      {submission && (
        <Suspense fallback={<DktLoader />}>
          <Submission
            idx={submissions.length - submissions.findIndex((s) => s === submission)}
            late={isBefore(taskEndDate, new Date(submission.date))}
            submissionId={submission.id}
          />
        </Suspense>
      )}
    </DktCard>
  );
}
