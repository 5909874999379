import React from 'react';

import { css } from '@emotion/css';
import { useMutation } from 'react-query';

import { dikastisApi } from 'dikastis-api';
import useSubmission from 'hooks/useSubmission';

// import CopyIcon from 'shared/icons/CopyIcon';
import ClickableIcon from 'shared/icons/ClickableIcon';
import DktAce from 'shared/DktAce';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import SubmissionStatus from 'shared/submission/SubmissionStatus';

import rejudgeIcon from 'assets/rejudge-icon.svg';
import { useToast } from 'toast/toast-context';

const iconStyle = css`
  margin-left: 20px;
`;
const statusStyle = css`
  margin: 0;
`;
const flexStyle = css`
  width: fit-content;
`;
const aceStyle = css`
  padding: 20px 0;
`;

function findOutput(submission) {
  const { outputs } = submission;
  if (!outputs || outputs.length === 0) return undefined;
  return outputs[0].output;
}

export default function SubmissionsBody({ submission }) {
  const { addToast } = useToast();
  const { fetchSubmissionResult } = useSubmission();
  const rejudgeMutation = useMutation(
    (s) => dikastisApi.put('/submissions', s),
    {
      onSuccess: () => {
        const { id } = submission;
        fetchSubmissionResult(id).then((submissionStatus) => {
          if (!submissionStatus) {
            addToast(500);
            return;
          }
          addToast(submissionStatus.status);
        });
      },
    },
  );
  const output = findOutput(submission);

  const rejudge = () => {
    const { id } = submission;
    rejudgeMutation.mutate({ id });
    addToast('JUDGING');
  };

  return (
    <div>
      <FlexLayout alignItems="center">
        <SubmissionStatus style={statusStyle}>
          {submission.status}
        </SubmissionStatus>
        <FlexLayout justifyContent="flex-end" style={flexStyle}>
          {/* <CopyIcon /> */}
          <div className={iconStyle}>
            <ClickableIcon src={rejudgeIcon} onClick={rejudge} />
          </div>
        </FlexLayout>
      </FlexLayout>
      {output && <DktText holder="p">{`Output: ${output}`}</DktText>}
      <DktAce readOnly code={submission.code} id="submission-viewer" style={aceStyle} />
    </div>
  );
}
