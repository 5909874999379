import React from 'react';

export default function EditIcon({ className }) {
  return (
    <svg className={className} fill="none" height="43" viewBox="0 0 43 43" width="43" xmlns="http://www.w3.org/2000/svg">
      <rect fill="white" height="42" rx="9.5" stroke="#172B4D" width="42" x="0.5" y="0.5" />
      <path d="M27.1018 12.5002L29.5767 14.9751L17.3691 27.1831C15.3675 29.1847 12.9093 30.8003 10.1957 31.6063C9.79854 31.7242 10.0093 31.4302 10.6172 30.2747C11.4726 28.6486 12.4966 27.1058 13.7958 25.8066L27.1018 12.5002Z" fill="#172B4D" />
      <path d="M29.2381 10.2374C29.9215 9.55402 31.0295 9.55402 31.713 10.2374C32.3964 10.9209 32.3964 12.0289 31.713 12.7123L30.4755 13.9497L28.0007 11.4749L29.2381 10.2374Z" fill="#172B4D" />
    </svg>
  );
}
