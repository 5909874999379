import React, { useState } from 'react';

import { css, cx } from '@emotion/css';

import FlexLayout from 'shared/FlexLayout';
import SearchIcon from 'shared/icons/SearchIcon';

const containerStyleBase = css`
  height: 55px;
  max-width: 970px;
  margin: auto;
  margin-top: 36px;
  background: #F4F4F4;
  border-radius: 15px;
  width: 100%;
`;
const inputStyle = css`
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background-color: inherit;
  border-radius: inherit;
  font: 16px Raleway, sans-serif;
`;
const iconStyle = css`
  margin: 0 20px;
`;

export default function SearchBar({
  containerStyle, onKeyDown, placeholder,
}) {
  const [search, setSearch] = useState(undefined);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onKeyDown(search);
    }
  };

  const onChange = ({ target }) => {
    const { value } = target;
    setSearch(value);
    if (value === '') onKeyDown(value);
  };

  return (
    <FlexLayout alignItems="center" style={cx(containerStyleBase, containerStyle)}>
      <SearchIcon className={iconStyle} />
      <input
        className={inputStyle}
        placeholder={placeholder || 'Place your search here and press enter'}
        onChange={onChange}
        onKeyDown={handleKeyDown}
      />
    </FlexLayout>
  );
}
