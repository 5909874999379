const useMockTask = () => (
  {
    tableColumns: [
      {
        Header: 'Title',
        accessor: 'title',
        sortType: (rowA, rowB, id) => {
          const a = rowA.original[id].props.title.toLowerCase();
          const b = rowB.original[id].props.title.toLowerCase();
          return a > b ? 1 : -1;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Difficulty',
        accessor: 'difficulty',
      },
      {
        Header: 'Weight',
        accessor: 'weight',
      },
    ],
  }
);

export default useMockTask;
