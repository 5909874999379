/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Link } from 'react-router-dom';
import { css } from '@emotion/css';

import DktDivider from 'shared/table/DktDivider';

const columnStyle = (content) => {
  if (content && content.includes && content.includes('+')) {
    return css`
      text-align: center;
      color: #32965D;
    `;
  }
  return css`
    text-align: center;
  `;
};

export default function TableBody({ ExpandableComponent, rowOnClick, tableProps }) {
  const {
    getTableBodyProps,
    prepareRow,
    rows,
    visibleColumns,
  } = tableProps;

  const renderRowSubComponent = React.useCallback(
    (row) => (
      <tr id="expansion">
        <td aria-label="expandable" colSpan={visibleColumns.length}>
          <ExpandableComponent row={row} />
        </td>
      </tr>
    ),
    [],
  );

  const openAndCollapse = (row, i) => {
    if (!ExpandableComponent) return;
    row.getToggleRowExpandedProps().onClick();
    if (rowOnClick) rowOnClick(row);
    rows.forEach((r, index) => {
      if (i !== index && r.isExpanded) {
        r.toggleRowExpanded(false);
      }
    });
  };

  const Cell = ({ children, link }) => {
    if (link) {
      return <Link className="content-holder" to={link}>{children}</Link>;
    }
    return <div className="content-holder">{children}</div>;
  };

  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row);
        return (
          <React.Fragment key={row.getRowProps().key}>
            <tr
              style={{ cursor: 'pointer' }}
              onClick={() => openAndCollapse(row, i)}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps([{
                  className: columnStyle(cell.value),
                }])}
                >
                  <Cell link={row.original.link}>
                    {cell.render('Cell')}
                  </Cell>
                </td>
              ))}
            </tr>
            {row.isExpanded ? renderRowSubComponent(row) : null}
            {i < rows.length - 1 && <DktDivider length={visibleColumns.length} />}
          </React.Fragment>
        );
      })}
    </tbody>
  );
}
