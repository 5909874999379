import React from 'react';

import { css } from '@emotion/css';

import DktButton from 'shared/DktButton';
import DktCard from 'shared/DktCard';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import GeneralPage from 'shared/GeneralPage';

const buttonStyle = css`
  margin-left: 20px;
`;
const cardStyle = css`
  max-width: 560px;
  width: 100%;
`;
const greetingStyle = css`
  margin-bottom: 32px;
`;

export default function AuthPage({ children, greeting }) {
  const buttons = (
    <FlexLayout>
      <DktButton negative href="sign-in" style={buttonStyle}>Sign In</DktButton>
      <DktButton href="sign-up" style={buttonStyle}>Sign Up</DktButton>
    </FlexLayout>
  );

  return (
    <GeneralPage buttons={buttons}>
      <DktText holder="h1" style={greetingStyle}>{greeting}</DktText>
      <DktCard style={cardStyle}>
        {children}
      </DktCard>
    </GeneralPage>
  );
}
