import React from 'react';

import ProblemBodyContainer from 'problems/problem/ProblemBodyContainer';
import ProblemPage from 'problems/problem/ProblemPage';

export default function ProblemBodyPage() {
  return (
    <ProblemPage>
      <ProblemBodyContainer />
    </ProblemPage>
  );
}
