import React from 'react';

import { css } from '@emotion/css';

// TODO - remove important statement
const divider = css`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  :hover {
    background-color: inherit !important;
  }
  div {
    border-bottom: 1px solid #F8B917;
  }
`;

export default function DktDivider({ length }) {
  return (
    <tr>
      <td aria-label="divider" className={divider} colSpan={length}>
        <div />
      </td>
    </tr>
  );
}
