import React, { useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';

import { useToast } from 'toast/toast-context';

import DktButton from 'shared/DktButton';
import DktSearchBar from 'shared/DktSearchBar';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import TaskList from 'shared/task/TaskList';
import TeamsList from 'teams/shared/TeamsList';
import { dikastisApi } from 'dikastis-api';

const barContainerStyle = css`
  margin-top: 36px;
`;
const barStyle = css`
  margin-top: 0px;
`;
const buttonStyle = css`
  margin-left: 20px;
`;
const taskListContainerStyle = css`
  margin-top: 20px;
`;
const teamContainerStyle = css`
  margin-top: 56px;
`;

export default function Teams() {
  const history = useHistory();
  const { addToast } = useToast();
  const { data: teams } = useQuery('/teams');
  const { data: tasks } = useQuery('/tasks/running');
  const tasksWithLink = tasks.map((task) => ({ ...task, link: `/teams/${task.teamId}/${task.id}` }));
  const [search, setSearch] = useState('');
  const maybeTeamId = search.length === 26;
  const filteredTeams = teams.filter((team) => (
    team.name.toLowerCase().includes(search.toLowerCase())
    || team.id.toLowerCase().includes(search.toLowerCase())
  )).map((team) => ({ ...team, link: `/teams/${team.id}` }));
  const sendInvite = useMutation(
    (teamId) => dikastisApi.post(`/teams/${teamId}/join-requests`),
    {
      onError: ({ response }) => {
        const { status } = response;
        if (status === 403) {
          addToast(status, 'Invite already sent or team member');
          return;
        }
        addToast(status);
      },
      onSuccess: () => {
        setSearch('');
        addToast(200);
      },
    },
  );

  const redirectNewTeam = () => {
    if (maybeTeamId) {
      sendInvite.mutate(search);
    } else {
      history.push('/teams/new');
    }
  };

  return (
    <>
      <FlexLayout style={barContainerStyle}>
        <DktSearchBar containerStyle={barStyle} placeholder="Insert team ID to join or search by name/ID" onKeyDown={setSearch} />
        <DktButton negative style={buttonStyle} onClick={redirectNewTeam}>{maybeTeamId ? 'Join Team' : 'Create Team'}</DktButton>
      </FlexLayout>
      <FlexLayout justifyContent="space-around" style={teamContainerStyle}>
        <div>
          <DktText holder="h2">Teams</DktText>
          <TeamsList teams={filteredTeams} />
        </div>
        <div>
          <DktText holder="h2">Running Tasks</DktText>
          <TaskList cardStyle={taskListContainerStyle} tasks={tasksWithLink} />
        </div>
      </FlexLayout>
    </>
  );
}
