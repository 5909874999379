import React, { Suspense } from 'react';

import { css } from '@emotion/css';

import { Redirect, Route } from 'react-router-dom';

import { Path } from 'router/routing';

import DktLoader from 'shared/DktLoader';
import DktRouter from 'router/DktRouter';
import FlexLayout from 'shared/FlexLayout';
import Header from 'header/Header';
// import Home from 'home/Home';
import NavBar from 'navigation/navbar/NavBar';
import OrganizationRouter from 'organizations/OrganizationRouter';
import ProblemsRouter from 'problems/ProblemsRouter';
import ProfilePage from 'person/ProfilePage';
import TabBar from 'navigation/tabbar/TabBar';
import TeamRouter from 'teams/TeamRouter';

const pageStyle = css`
  height: 100%;
`;
const contentStyle = css`
  width: 100%;
  padding: 40px;
  overflow: scroll;
`;

function AppLayout() {
  return (
    <FlexLayout style={pageStyle}>
      <NavBar />
      <div className={contentStyle}>
        <Header>
          <TabBar />
        </Header>
        <Suspense fallback={<DktLoader />}>
          <DktRouter>
            <Redirect exact path={Path.CATEGORIES} to="/problems" />
            <Route component={ProfilePage} path={Path.PROFILE} />
            <Route component={ProblemsRouter} path={Path.PROBLEMS} />
            <Route component={OrganizationRouter} path={Path.ORGANIZATIONS_HOME} />
            <Route component={TeamRouter} path={Path.TEAMS_HOME} />
          </DktRouter>
        </Suspense>
      </div>
    </FlexLayout>
  );
}

export default function AuthenticatedApp() {
  return (
    <DktRouter>
      <Route exact path={[Path.SIGN_IN, Path.SIGN_UP, '/']}>
        <Redirect to={Path.CATEGORIES} />
      </Route>
      <Route
        component={AppLayout}
        path={[
          Path.CATEGORIES,
          Path.PROFILE,
          Path.PROBLEMS,
          Path.ORGANIZATIONS_HOME,
          Path.TEAMS_HOME,
        ]}
      />
    </DktRouter>
  );
}
