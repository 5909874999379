import React from 'react';

import { css } from '@emotion/css';
import { useAuth } from 'auth/auth-context';

import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import PersonChangeEmail from 'person/form/PersonChangeEmail';
import PersonChangePassword from 'person/form/PersonChangePassword';

import picture from 'assets/profile.jpeg';

const pictureStyle = css`
  border-radius: 100%;
  margin-bottom: 20px;
`;
const containerStyle = css`
  margin-top: 40px;
`;
const formContainerSytle = css`
  > form:last-child {
    margin-left: 80px;
  }
`;

export default function ProfilePage() {
  const { user } = useAuth();
  const { email, name, username } = user || {};

  return (
    <FlexLayout alignItems="center" flexDirection="column" justifyContent="center" style={containerStyle}>
      <img alt="Profile" className={pictureStyle} height={160} src={picture} />
      <DktText holder="h2">{name}</DktText>
      <DktText holder="h3">{`@${username}`}</DktText>
      <DktText holder="h4">{email}</DktText>
      <FlexLayout justifyContent="center" style={formContainerSytle}>
        <PersonChangeEmail />
        <PersonChangePassword />
      </FlexLayout>
    </FlexLayout>
  );
}
