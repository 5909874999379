import React, { useEffect } from 'react';

import { useField } from 'formik';

import DktFormField from 'shared/form/DktFormField';
import DktText from 'shared/DktText';
import DynamicProblemForm from 'problems/form/DynamicProblemForm';
import FlexLayout from 'shared/FlexLayout';

export default function ProblemTypeForm({ problem }) {
  const [{ value: problemType }] = useField('problemType');
  const [,, { setValue: setJudgingLanguage }] = useField('judgingLanguage');
  const judgingLanguage = problem.setJudgingLanguage === 'PLAIN_TEXT' ? 'PYTHON3' : problem.setJudgingLanguage;

  useEffect(() => {
    if (problemType === 'STATIC') {
      setJudgingLanguage('PLAIN_TEXT');
    } else {
      setJudgingLanguage(judgingLanguage || 'PYTHON3');
    }
  }, [problemType]);

  return (
    <>
      <FlexLayout justifyContent="flex-start">
        <DktFormField name="problemType" title="Static" type="radio" value="STATIC" />
        <div style={{ width: '80px' }} />
        <DktFormField name="problemType" title="Dynamic" type="radio" value="DYNAMIC" />
      </FlexLayout>
      <DktText>
        {'You can create either static test cases or dynamic.'
         + 'If you choose dynamic you should also provide a judging '
         + 'code to verify the answers.'}
      </DktText>
      {problemType === 'DYNAMIC' && <DynamicProblemForm problem={problem} />}
    </>
  );
}
