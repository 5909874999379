import React from 'react';

import { css, cx } from '@emotion/css';

import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import SubmissionStatusIcon from 'shared/icons/SubmissionStatusIcon';

const containerStyle = css`
  overflow-x: scroll;
`;
const commonSelectorStyle = css`
  margin-top: 8px;
  height: 8px;
  width: 56px;
`;
const selectorStyle = css`
  background: #EEBF4D;
  box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
`;
const statusContainerStyle = css`
  text-align: center;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  margin: 24px 0 24px 20px;
  :first-child {
    margin-left: 0;
  }
`;

export default function StatusList({ list, select, selectedItem }) {
  const divider = (show) => {
    if (show) return <div className={cx(commonSelectorStyle, selectorStyle)} />;
    return <div className={commonSelectorStyle} />;
  };

  return (
    <FlexLayout style={containerStyle}>
      {list.map((item, idx) => {
        const itemId = item.id ? item.id : item.displayId;
        const itemIdx = item.displayId !== undefined ? item.displayId + 1 : list.length - idx;
        return (
          <button key={itemId} className={statusContainerStyle} type="button" onClick={() => select(item)}>
            <SubmissionStatusIcon status={item.status} />
            <DktText holder="p">{itemIdx}</DktText>
            {divider(selectedItem === item)}
          </button>
        );
      })}
    </FlexLayout>
  );
}
