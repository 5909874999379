import React from 'react';

import { css } from '@emotion/css';

import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import RoundIcons from 'shared/icons/statusIcons/RoundIcons';

const language = {
  HASKELL: 'Haskell',
  JAVA8: 'Java 8',
  PYTHON3: 'Python 3',
};

const flexStyle = css`
  box-sizing: border-box;
  padding: 20px 12px;
  cursor: pointer;
  :hover {
    background-color: #EEE;
  }
`;
const dateStyle = css`
  width: 50%;
  font-weight: 600;
`;
const textStyle = css`
  width: 30%;
  text-align: end;
  font-weight: 600;
`;

export default function SubmissionHeader({ submission }) {
  const submissionDate = new Date(submission.date).toLocaleString();

  return (
    <>
      <FlexLayout alignItems="center" justifyContent="space-between" style={flexStyle}>
        <DktText holder="h4" style={dateStyle}>{submissionDate}</DktText>
        <RoundIcons status={submission.status} />
        {submission.runtime && <DktText holder="h4" style={textStyle}>{`${submission.runtime} ms`}</DktText>}
        <DktText holder="h4" style={textStyle}>{language[submission.language]}</DktText>
      </FlexLayout>
    </>
  );
}
