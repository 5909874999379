import React from 'react';

import { css } from '@emotion/css';

const verticalStyle = css`
  width: 1px;
  background-color: #F8B917;
`;
const horizontalStyle = css`
  height: 1px;
  background-color: #F8B917;
`;

export default function DikastisDivider({ vertical }) {
  const dividerStyle = vertical ? verticalStyle : horizontalStyle;

  return <div className={dividerStyle} />;
}
