const localStorage = '@Dikastis:';

function getCode(problemId) {
  const code = window.localStorage.getItem(localStorage + problemId);
  if (!code) return '';
  return code;
}

function saveCode(problemId, code) {
  window.localStorage.setItem(localStorage + problemId, code);
}

export {
  getCode,
  saveCode,
};
