import React from 'react';

import { dikastisApi } from 'dikastis-api';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useToast } from 'toast/toast-context';

import ProblemForm from 'problems/form/ProblemForm';

export default function ProblemCreationContainer() {
  const { addToast } = useToast();
  const history = useHistory();
  const createProblem = useMutation(
    (problemForm) => dikastisApi.post('/problems', problemForm),
    {
      onError: ({ response }) => {
        const { status } = response;
        addToast(status);
      },
      onSuccess: ({ data: problem }) => {
        history.push(`/problems/${problem.id}`);
      },
    },
  );

  const onSubmit = createProblem.mutate;

  return <ProblemForm onSubmit={onSubmit} />;
}
