import React, { useState } from 'react';

import { css } from '@emotion/css';
import { useField } from 'formik';

import { dikastisApi } from 'dikastis-api';
import { useToast } from 'toast/toast-context';

import DktButton from 'shared/DktButton';
import DktFormField from 'shared/form/DktFormField';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import MemberList from 'shared/members/MemberList';

import addSymbol from 'assets/add-symbol.svg';

const fieldStyle = css`
  width: 100%;
`;
const listContainerStyle = css`
  width: 100%;
  :last-child {
    margin-left: 60px;
  }
`;
const addButtonStyle = css`
  height: fit-content;
  padding: 8px 8px 2px;
  margin: 0 0 4px 40px;
`;

export default function MemberForm({ team }) {
  const { addToast } = useToast();
  const [{ value: invites }] = useField('invites');
  const [invitedMembers, setInvitedMembers] = useState(invites
    ? invites.map((person) => ({ person })) : []);
  const [addMemberField] = useField('addMember');

  const addMember = (username) => {
    dikastisApi.get(`/people/${username}`).then(({ data }) => {
      const member = {
        person: data,
      };
      dikastisApi.patch(`/teams/${team.id}/invites/${username}`).then(() => {
        setInvitedMembers((prev) => [...prev, member]);
      }).catch(({ response }) => {
        addToast(response.status);
      });
    }).catch(({ response }) => {
      addToast(response.status);
    });
  };

  return (
    <>
      <FlexLayout alignItems="flex-end" justifyContent="space-between">
        <DktFormField fieldStyle={fieldStyle} name="addMember" placeholder="@username" title="Add member" />
        <DktButton negative style={addButtonStyle} onClick={() => addMember(addMemberField.value)}>
          <img alt="Add symbol" src={addSymbol} />
        </DktButton>
      </FlexLayout>
      <FlexLayout>
        <div className={listContainerStyle}>
          <DktText holder="form-title">Invited Members</DktText>
          <MemberList members={invitedMembers} />
        </div>
        <div className={listContainerStyle}>
          <DktText holder="form-title">Members</DktText>
          <MemberList edit members={team.members} />
        </div>
      </FlexLayout>
    </>
  );
}
