import React from 'react';

import useDikastisRouting from 'router/useDikastisRouting';

import DktProfile from 'header/DktProfile';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

export default function Header({ children }) {
  const { domain } = useDikastisRouting();

  return (
    <FlexLayout alignItems="center" justifyContent="space-between">
      <DktText holder="h1">{ `${domain}/` }</DktText>
      { children }
      <DktProfile />
    </FlexLayout>
  );
}
