import React from 'react';

import ReactDiffViewer from 'react-diff-viewer';
import { css } from '@emotion/css';

import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

const containerStyle = css`
  margin-top: 20px;
`;
const boxStyle = css`
  background: #F4F4F4;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  margin-bottom: 20px;
  overflow-x: scroll;
  h4 {
    font-weight: 600;
    margin-bottom: 12px;
  }
`;
const testConfig = {
  diffContainer: {
    font: '16px Raleway, sans-serif',
  },
};

function TestBox({ data, title }) {
  return (
    <div className={boxStyle}>
      <DktText holder="h4">{title}</DktText>
      <DktText holder="monospace">{data}</DktText>
    </div>
  );
}

export default function SubmissionOutput({ submissionOutput }) {
  const { output, status, testCase } = submissionOutput;

  const userOutputCompare = () => {
    if (status === 'WRONG_ANSWER') {
      return <ReactDiffViewer splitView leftTitle="Test Output" newValue={output} oldValue={testCase.output} rightTitle="User output" styles={testConfig} />;
    }
    return <TestBox data={output} title="User output" />;
  };

  return (
    <div className={containerStyle}>
      <TestBox data={testCase.input} title="Input" />
      <FlexLayout justifyContent="space-between">
        {userOutputCompare()}
      </FlexLayout>
    </div>
  );
}
