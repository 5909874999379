import React from 'react';

import { css } from '@emotion/css';

import { dikastisApi } from 'dikastis-api';
import useDikastisRouting from 'router/useDikastisRouting';

import ClickableIcon from 'shared/icons/ClickableIcon';
import DktCard from 'shared/DktCard';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

import filledStarIcon from 'assets/filled-star.svg';
import picture from 'assets/profile.jpeg';
import removeMemberIcon from 'assets/remove-member.svg';
import starIcon from 'assets/star.svg';
import { useToast } from 'toast/toast-context';

const pictureStyle = css`
  border-radius: 100%;
  margin-right: 12px;
`;
const blueText = css`
  color: #172B4D;
`;
const memberStyle = css`
  margin: 20px auto;
  button {
    display: none;
  }
  :hover {
    button {
      display: flex;
    }
  }
`;

const categoryOrder = {
  ADMIN: 4,
  ASSISTANT: 2,
  LEADER: 0,
  MEMBER: 5,
  TEACHER: 1,
  undefined: -1,
};

const getRole = {
  ADMIN: 'Admin',
  ASSISTANT: 'Teaching Assistant',
  LEADER: 'Leader',
  MEMBER: 'Member',
  TEACHER: 'Teacher',
  undefined: '',
};

const getNewRole = {
  ADMIN: 'MEMBER',
  ASSISTANT: 'TEACHER',
  LEADER: 'LEADER',
  MEMBER: 'ADMIN',
  TEACHER: 'TEACHER_ASSISTANT',
  undefined: '',
};

function convertMemberList(members) {
  const categories = Object.entries(categoryOrder).reduce((acc, [role, order]) => {
    acc[role] = {
      memberList: [],
      order,
      title: getRole[role],
    };
    return acc;
  }, {});
  members.forEach((member) => categories[member.role].memberList.push(member));
  return Object.values(categories)
    .filter((category) => category.memberList.length > 0)
    .sort((a, b) => a.order - b.order);
}

function RoleButtons({ member, role }) {
  const { params } = useDikastisRouting();
  const { teamId } = params;
  const { addToast } = useToast();
  const changeRole = () => {
    dikastisApi.patch(`/teams/${teamId}/member`, { person: member, role: getNewRole[role] })
      .then(() => {
        addToast(200, 'Role changed, save to apply');
      })
      .catch(({ response }) => {
        addToast(response.status);
      });
  };

  const removeMember = () => {
    dikastisApi.delete(`/teams/${teamId}/member/${member.username}`)
      .then(() => {
        addToast(200, 'Member removed, save to apply');
      })
      .catch(({ response }) => {
        addToast(response.status);
      });
  };

  return (
    <FlexLayout alignItems="center" justifyContent="flex-end">
      <ClickableIcon alt="Change role" src={role === 'MEMBER' ? starIcon : filledStarIcon} onClick={changeRole} />
      <ClickableIcon alt="Remove member" src={removeMemberIcon} onClick={removeMember} />
    </FlexLayout>
  );
}

function Member({ edit, member, role }) {
  return (
    <FlexLayout justifyContent="space-between" style={memberStyle}>
      <FlexLayout alignItems="center">
        <img alt="Profile" className={pictureStyle} height={40} src={picture} width={40} />
        <DktText holder="h3">{member.name}</DktText>
      </FlexLayout>
      {edit && categoryOrder[role] >= 4 && <RoleButtons member={member} role={role} />}
    </FlexLayout>
  );
}

export default function MemberList({ edit, members }) {
  const categories = convertMemberList(members);

  return (
    <DktCard>
      {categories.map(({ memberList, title }) => (
        <div key={title}>
          <DktText holder="h3" style={blueText}>{title}</DktText>
          {memberList.map(({ person, role }) => (
            <Member key={person.username} edit={edit} member={person} role={role} />
          ))}
        </div>
      ))}
    </DktCard>
  );
}
