import React from 'react';

import { css } from '@emotion/css';

import { useAuth } from 'auth/auth-context';

import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import OptionsIcon from 'header/OptionsIcon';

import picture from 'assets/profile.jpeg';

const flexBoxStyle = css`
  width: fit-content;
`;
const greetingStyle = css`
  margin-right: 12px;
  font-weight: 400;
  width: max-content;
`;
const pictureStyle = css`
  border-radius: 100%;
  margin-right: 12px;
`;

export default function DktProfile() {
  const { user } = useAuth();

  return (
    <FlexLayout alignItems="center" style={flexBoxStyle}>
      <DktText holder="h3" style={greetingStyle}>{`Oi, ${user.name}!`}</DktText>
      <img alt="Profile" className={pictureStyle} height={54} src={picture} width={54} />
      <OptionsIcon />
    </FlexLayout>
  );
}
