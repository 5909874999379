import React from 'react';

export default function ProblemsIcon({ fill = 'none' }) {
  return (
    <svg fill={fill} height="28" viewBox="0 0 32 28" width="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.39648 10.8926H8.01904C9.40332 10.8926 10.2236 10.0894 10.2236 8.72217V3.03125C10.2236 1.66406 9.40332 0.86084 8.01904 0.86084H2.39648C0.995117 0.86084 0.191895 1.66406 0.191895 3.03125V8.72217C0.191895 10.0894 0.995117 10.8926 2.39648 10.8926ZM14.0518 2.43311H31.022C31.4663 2.43311 31.8081 2.09131 31.8081 1.64697C31.8081 1.20264 31.4663 0.86084 31.022 0.86084H14.0518C13.6074 0.86084 13.2656 1.20264 13.2656 1.64697C13.2656 2.09131 13.6074 2.43311 14.0518 2.43311ZM14.0518 10.8926H31.022C31.4663 10.8926 31.8081 10.5679 31.8081 10.1064C31.8081 9.66211 31.4663 9.32031 31.022 9.32031H14.0518C13.6074 9.32031 13.2656 9.66211 13.2656 10.1064C13.2656 10.5679 13.6074 10.8926 14.0518 10.8926ZM2.39648 27.8286H8.01904C9.40332 27.8286 10.2236 27.0254 10.2236 25.6582V19.9673C10.2236 18.6001 9.40332 17.7969 8.01904 17.7969H2.39648C0.995117 17.7969 0.191895 18.6001 0.191895 19.9673V25.6582C0.191895 27.0254 0.995117 27.8286 2.39648 27.8286ZM14.0518 19.3691H31.022C31.4663 19.3691 31.8081 19.0273 31.8081 18.583C31.8081 18.1216 31.4663 17.7969 31.022 17.7969H14.0518C13.6074 17.7969 13.2656 18.1216 13.2656 18.583C13.2656 19.0273 13.6074 19.3691 14.0518 19.3691ZM14.0518 27.8286H31.022C31.4663 27.8286 31.8081 27.4868 31.8081 27.0425C31.8081 26.5981 31.4663 26.2563 31.022 26.2563H14.0518C13.6074 26.2563 13.2656 26.5981 13.2656 27.0425C13.2656 27.4868 13.6074 27.8286 14.0518 27.8286Z"
      />
    </svg>
  );
}
