import addSeconds from 'date-fns/addSeconds';

import { dikastisApi } from 'dikastis-api';

const localStorageTokenKey = '@Dikastis:token';
const localStorageTokenExpirationKey = '@Dikastis:token-expiration';
const localStorageUsernameKey = '@Dikastis:username';

function getAuthData() {
  return {
    expiration: window.localStorage.getItem(localStorageTokenExpirationKey),
    token: window.localStorage.getItem(localStorageTokenKey),
    username: window.localStorage.getItem(localStorageUsernameKey),
  };
}

function saveAuthData({ expiration, token, username }) {
  window.localStorage.setItem(localStorageTokenKey, token);
  window.localStorage.setItem(localStorageUsernameKey, username);
  window.localStorage.setItem(localStorageTokenExpirationKey, expiration);
}

function login({ password, username }, setAuthData) {
  return dikastisApi.post('/people/login', {
    password,
    username,
  }).then((response) => {
    const expirationDate = addSeconds(new Date(), response.data.expires_in);
    const authData = {
      expiration: expirationDate.toISOString(),
      token: response.data.access_token,
      username: response.data.username,
    };
    saveAuthData(authData);
    setAuthData(authData);
  });
}

function register({
  email,
  name,
  password,
  username,
}) {
  return dikastisApi.post('/people', {
    email,
    name,
    password,
    username,
  });
}

function logout() {
  window.localStorage.removeItem(localStorageTokenKey);
  window.localStorage.removeItem(localStorageTokenExpirationKey);
  window.localStorage.removeItem(localStorageUsernameKey);
}

export {
  getAuthData,
  login,
  logout,
  register,
};
