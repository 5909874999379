import React from 'react';

import DktFormField from 'shared/form/DktFormField';
import FlexLayout from 'shared/FlexLayout';
import ProblemValidationForm from './ProblemValidationForm';

const difficulties = {
  EASY: 'Easy',
  HARD: 'Hard',
  MEDIUM: 'Medium',
};

// TODO - get problem topics
export default function ProblemBodyForm({ edit, fieldStyle }) {
  return (
    <>
      <DktFormField fieldStyle={fieldStyle} name="title" placeholder="Problem title" title="Title" />
      {/* <DktFormField fieldStyle={fieldStyle}
      name="topics" placeholder="Select a topic" title="Topic" /> */}
      <FlexLayout justifyContent="space-between">
        <DktFormField as="select" name="difficulty" placeholder="Select a difficulty" title="Difficulty">
          {Object.entries(difficulties).map(([value, display]) => (
            <option key={value} value={value}>{display}</option>
          ))}
        </DktFormField>
        <DktFormField name="timeLimit" placeholder="0 ms" title="Time Limit" />
        <DktFormField name="organizationId" placeholder="Organization ID (optional)" title="Organization" />
      </FlexLayout>
      {edit && <ProblemValidationForm />}
      <DktFormField name="description" placeholder="Problem description" title="Problem statement" type="preview" />
      <DktFormField name="inputDescription" placeholder="Input description" title="Input description" type="preview" />
      <DktFormField name="outputDescription" placeholder="Output description" title="Output description" type="preview" />
    </>
  );
}
