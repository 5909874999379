import React from 'react';

import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { css, cx } from '@emotion/css';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Path } from 'router/routing';
import { dikastisApi } from 'dikastis-api';
import { useToast } from 'toast/toast-context';

import AuthPage from 'auth/AuthPage';
import DktButton from 'shared/DktButton';
import DktFormField from 'shared/form/DktFormField';
import FlexLayout from 'shared/FlexLayout';

const signUpStyle = css`
  margin-top: 40px;
`;
const formStyle = css`
  display: flex;
  flex-direction: column;
`;
const fieldStyle = css`
  width: 100%;
`;

const validationSchema = yup.object().shape({
  email: yup.string().email('Email invalid').required('Is required'),
});

function PasswordRecoveryForm({ children }) {
  const { addToast } = useToast();
  const history = useHistory();
  const requestRecovery = useMutation(
    (passwordRecoveryEmail) => dikastisApi.post('/people/password-recovery', passwordRecoveryEmail),
    {
      onError: () => {
        addToast(500);
      },
      onSuccess: () => {
        addToast(200, 'Link sent, if we have your account');
        history.push(Path.SIGN_IN);
      },
    },
  );

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(requestRecovery.mutate)}
    >
      <Form className={cx(formStyle)}>{children}</Form>
    </Formik>
  );
}

export default function PasswordRecoveryRequest() {
  return (
    <AuthPage greeting="Reset your password">
      <FlexLayout flexDirection="column" justifyContent="center">
        <PasswordRecoveryForm>
          <DktFormField fieldStyle={fieldStyle} name="email" placeholder="Your e-mail address" title="Email" type="email" />
          <FlexLayout justifyContent="flex-end">
            <DktButton submit style={signUpStyle}>Send link</DktButton>
          </FlexLayout>
        </PasswordRecoveryForm>
      </FlexLayout>
    </AuthPage>
  );
}
