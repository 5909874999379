import React from 'react';

import { css } from '@emotion/css';
import format from 'date-fns/format';
import { useQuery } from 'react-query';

import useDikastisRouting from 'router/useDikastisRouting';

import DktAce from 'shared/DktAce';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import SubmissionOutputList from 'organizations/person/SubmissionOutputList';
import SubmissionStatus from 'shared/submission/SubmissionStatus';

const containerStyle = css`
  margin-top: 20px;
`;
const lateStyle = css`
  color: #C20114;
`;
const submissionLanguageRuntimeStyle = css`
  width: fit-content;
  margin-left: 40px;
`;

export default function Submission({ idx, late, submissionId }) {
  const { params } = useDikastisRouting();
  const { organizationId, personId, taskId } = params;
  const { data: submission } = useQuery(`/organizations/${organizationId}/${taskId}/${personId}/submissions/${submissionId}`);
  const {
    code, date, language, outputs, runtime, status,
  } = submission;

  return (
    <div className={containerStyle}>
      <DktText>
        <strong>Submission </strong>
        {idx}
      </DktText>
      <FlexLayout alignItems="center" justifyContent="space-between">
        <SubmissionStatus>{status}</SubmissionStatus>
        <FlexLayout alignItems="flex-end" className={submissionLanguageRuntimeStyle} flexDirection="column">
          <DktText style={late ? lateStyle : undefined}>
            <strong>Date: </strong>
            {format(new Date(date), 'dd/MM/yyyy - HH:mm:ss.SSS')}
          </DktText>
          <DktText>
            <strong>Language: </strong>
            {language}
          </DktText>
          <DktText>
            <strong>Runtime: </strong>
            {runtime}
          </DktText>
        </FlexLayout>
      </FlexLayout>
      <DktAce readOnly code={code} />
      {outputs && outputs.length > 0 && <SubmissionOutputList outputs={outputs} />}
    </div>
  );
}
