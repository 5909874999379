const useMockProblems = () => (
  {
    tableColumns: [
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Difficulty',
        accessor: 'difficulty',
      },
    ],
  }
);

export default useMockProblems;
