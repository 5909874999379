import React from 'react';

import { css } from '@emotion/css';

import DktText from 'shared/DktText';

const containerStyle = css`
  background-color: #F4F4F4;
  border-radius: 15px;
  padding: 12px;
  box-sizing: border-box;
`;
const caseStyle = css`
  margin-top: 16px;
  margin-bottom: 8px;
`;
const separatorStyle = css`
  margin-top: 16px;
  margin-bottom: 4px;
`;

export default function TestCase({ num, testCase }) {
  return (
    <>
      <DktText holder="p" style={caseStyle}>{`Case: ${num}`}</DktText>
      <div className={containerStyle}>
        <DktText holder="p"><strong>Input</strong></DktText>
        <DktText holder="monospace">{testCase.input}</DktText>
        <DktText holder="p" style={separatorStyle}><strong>Output</strong></DktText>
        <DktText holder="monospace">{testCase.output}</DktText>
      </div>
    </>
  );
}
