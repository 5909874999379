import React from 'react';

import { css, cx } from '@emotion/css';

const styleBase = css`
  display: block;
  color: #FFF;
  width: 120px;
  height: 40px;
  font-size: 20px;
  border-radius: 10px;
  margin: auto;
  text-align: center;
  line-height: 40px;
`;
const sentStyle = css`
  background-color: #32965D;
`;
const readyStyle = css`
  background-color: #ED7E40;
`;
const pendingStyle = css`
  background-color: #909090;
`;
const lateStyle = css`
  background-color: #C20114;
`;

export default function FeedbackStatus({ status, style }) {
  const composedStyle = cx(
    styleBase,
    style,
    { [sentStyle]: status === 'SENT' },
    { [sentStyle]: status === 'REPLIED' },
    { [readyStyle]: status === 'READY' },
    { [pendingStyle]: status === 'PENDING' },
    { [lateStyle]: status === 'LATE' },
  );

  return (
    <div className={composedStyle}>{status}</div>
  );
}
