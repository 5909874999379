import React from 'react';

import Loader from 'react-loader-spinner';

import clockMark from 'assets/status-icons/clock-circle.svg';
import solvedMark from 'assets/status-icons/approved-circle.svg';
import triedMark from 'assets/status-icons/yellow-exclamation.svg';
import wrongMark from 'assets/status-icons/red-x-circle.svg';

const statusIcon = {
  ACCEPTED: <img alt="Accepted" height={20} src={solvedMark} width={20} />,
  BLANK: <div />,
  COMPILATION_ERROR: <img alt="Compilation error" height={20} src={triedMark} width={20} />,
  FINISHED: <img alt="Solved" height={20} src={solvedMark} width={20} />,
  PENDING: <Loader color="#172B4D" height={30} type="Rings" width={30} />,
  RUNTIME_ERROR: <img alt="Runtime error" height={20} src={triedMark} width={20} />,
  SOLVED: <img alt="Solved" height={20} src={solvedMark} width={20} />,
  TIME_LIMIT_EXCEPTION: <img alt="Timit limit exceeded" height={20} src={clockMark} width={20} />,
  TRIED: <img alt="Treid" height={20} src={triedMark} width={20} />,
  WRONG_ANSWER: <img alt="Wrong answer" height={20} src={wrongMark} width={20} />,
};

export default function RoundIcons({ status }) {
  const icon = statusIcon[status] || statusIcon.BLANK;
  return icon;
}
