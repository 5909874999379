import { dikastisApi } from 'dikastis-api';

async function fetchSubmissionResult(id, timeout = 0) {
  if (timeout > 8) return undefined;
  return dikastisApi.get(`/submissions/${id}`).then(({ data }) => {
    const { status } = data;
    if (status === 'PENDING') {
      return new Promise(
        (resolve) => setTimeout(resolve, (2 ** timeout) * 1000),
      ).then(() => fetchSubmissionResult(id, timeout + 1));
    }
    return data;
  });
}

export default () => ({ fetchSubmissionResult });
