import React from 'react';

import {
  FieldArray, useField,
} from 'formik';
import { UlidMonotonic } from 'id128';
import { css } from '@emotion/css';

import DktButton from 'shared/DktButton';
import DktFormField from 'shared/form/DktFormField';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

const testCaseStyle = css`
  margin-top: 40px;
`;
const addTestCaseStyle = css`
  margin-top: 20px;
`;
const headStyle = css`
  margin: 40px 0 0;
`;

export default function ProblemTestCasesForm({ fieldStyle }) {
  const [{ value: testCases }] = useField('testCases');

  return (
    <>
      <DktText holder="h2" style={headStyle}>Test Cases</DktText>
      <FieldArray name="testCases">
        {({ push, remove }) => (
          <div>
            {testCases.map((testCase, idx) => (
              <div key={testCase.key}>
                <FlexLayout alignItems="flex-end" justifyContent="space-between">
                  <DktText holder="h3" style={testCaseStyle}>{`Test case ${idx + 1}`}</DktText>
                  <DktButton
                    negative
                    onClick={() => remove(idx)}
                  >
                    X
                  </DktButton>
                </FlexLayout>
                <DktFormField as="textarea" fieldStyle={fieldStyle} name={`testCases.${idx}.input`} placeholder="Test case input" title="Input" />
                <DktFormField as="textarea" fieldStyle={fieldStyle} name={`testCases.${idx}.output`} placeholder="Test case output" title="Output" />
                <DktFormField name={`testCases.${idx}.isSample`} title="Is sample" type="checkbox" />
                <DktFormField as="textarea" fieldStyle={fieldStyle} name={`testCases.${idx}.hint`} placeholder="Give a hint for this test case and it will be visible to the user if not accepted. (optional)" title="Hint" />
              </div>
            ))}
            <DktButton
              negative
              style={addTestCaseStyle}
              onClick={() => push({
                hint: '',
                input: '',
                isSample: false,
                key: UlidMonotonic.generate().toRaw(),
                output: '',
              })}
            >
              Add new
            </DktButton>
          </div>
        )}
      </FieldArray>
    </>
  );
}
