import React from 'react';

import { Link } from 'react-router-dom';
import { css } from '@emotion/css';
import { useQuery } from 'react-query';

import DktButton from 'shared/DktButton';
import DktMarkdown from 'shared/DktMarkdown';
import DktText from 'shared/DktText';
import EditIcon from 'shared/icons/EditIcon';
import FlexLayout from 'shared/FlexLayout';
import TestCase from 'problems/shared/TestCase';

const sectionTitleStyle = css`
  margin-top: 16px;
  font: 24px Viga, sans-serif;
  color: #172B4D;
`;
const solveContainerStyle = css`
  width: fit-content;
  height: fit-content;
`;
const solveStyle = css`
  margin: 0 20px;
`;

function Section({ content, title }) {
  return (
    <>
      <DktText holder="p" style={sectionTitleStyle}>{title}</DktText>
      <DktMarkdown content={content} />
    </>
  );
}

export default function ProblemBody({ id }) {
  const { data: problem } = useQuery(`/problems/${id}`);
  const {
    authorUsername,
    description,
    inputDescription,
    outputDescription,
    role,
    testCases,
    timeLimit,
    valid,
  } = problem;

  return (
    <div>
      <FlexLayout justifyContent="space-between">
        <div>
          <DktText holder="h4">
            <strong>Time limit: </strong>
            {timeLimit}
            ms
          </DktText>
          <DktText>
            <strong>Author: </strong>
            {authorUsername}
          </DktText>
          <DktText>
            <strong>Valid: </strong>
            {valid.toString()}
          </DktText>
        </div>
        <FlexLayout style={solveContainerStyle}>
          {(role === 'AUTHOR' && (
            <Link to={`/problems/${id}/edit`}>
              <EditIcon />
            </Link>
          ))}
          <DktButton href={`/problems/${id}`} style={solveStyle}>Solve</DktButton>
        </FlexLayout>
      </FlexLayout>
      <Section content={description} title="Problem Statement" />
      <Section content={inputDescription} title="Input" />
      <Section content={outputDescription} title="Output" />
      <DktText holder="p" style={sectionTitleStyle}>Examples</DktText>
      {testCases.filter(({ isSample }) => isSample).map((testCase, i) => (
        <TestCase key={testCase.displayId} num={i + 1} testCase={testCase} />
      ))}
    </div>
  );
}
