import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import { Path } from 'router/routing';

import AccountActivation from 'auth/AccountActivation';
import DktRouter from 'router/DktRouter';
import PasswordRecovery from 'auth/PasswordRecovery';
import PasswordRecoveryRequest from 'auth/PasswordRecoveryRequest';
import SignIn from 'auth/SignIn';
import SignUp from 'auth/SignUp';

export default function UnauthenticatedApp() {
  return (
    <DktRouter>
      <Route exact component={SignIn} path={Path.SIGN_IN} />
      <Route exact component={SignUp} path={Path.SIGN_UP} />
      <Route exact component={PasswordRecoveryRequest} path={Path.PASSWORD_RECOVERY_REQUEST} />
      <Route exact component={PasswordRecovery} path={Path.PASSWORD_RECOVERY} />
      <Route exact component={AccountActivation} path={Path.ACCOUNT_ACTIVATION} />
      <Redirect to={Path.SIGN_IN} />
    </DktRouter>
  );
}
