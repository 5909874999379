import React from 'react';

import { css } from '@emotion/css';

import DktCard from 'shared/DktCard';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

const cardStyle = css`
  background-color: #EEBF4D;
  margin: 20px auto;
`;
const amountBoxStyle = css`
  width: auto;
`;

export default function TeamsList({ teams }) {
  return teams.map((team) => (
    <DktCard key={team.id} linkTo={team.link} style={cardStyle}>
      <FlexLayout alignItems="center" justifyContent="space-between">
        <div>
          <DktText holder="h3">{team.name}</DktText>
          {team.leaderName && <DktText holder="h4">{`Leader: ${team.leaderName}`}</DktText>}
        </div>
        <FlexLayout alignItems="flex-end" flexDirection="column" style={amountBoxStyle}>
          <DktText holder="h4">{`${team.membersCount} members`}</DktText>
          {team.adminsCount && <DktText holder="h4">{`${team.adminsCount} admins`}</DktText>}
        </FlexLayout>
      </FlexLayout>
    </DktCard>
  ));
}
