import React, { useEffect } from 'react';

import { useMutation, useQuery } from 'react-query';
import format from 'date-fns/format';

import { dikastisApi } from 'dikastis-api';
import useDikastisRouting from 'router/useDikastisRouting';
import { useHistory } from 'react-router-dom';

import TaskForm from 'teams/task/form/TaskForm';

export default function TaskEditContainer() {
  const { params } = useDikastisRouting();
  const { taskId, teamId } = params;
  const { data: task } = useQuery(`/tasks/${taskId}`);
  const history = useHistory();
  const createTask = useMutation(
    (taskForm) => dikastisApi.put('/tasks', taskForm),
    {
      onSuccess: () => {
        history.push(`/teams/${teamId}/${taskId}`);
      },
    },
  );
  const deleteTask = useMutation(
    () => dikastisApi.delete(`/tasks/${taskId}`),
    {
      onSuccess: () => {
        history.push(`/teams/${teamId}`);
      },
    },
  );
  const startDate = new Date(task.beginTime);
  const endDate = new Date(task.endTime);
  const form = {
    ...task,
    endDateTime: {
      date: format(endDate, 'yyyy-MM-dd'),
      time: format(endDate, 'HH:mm:ss'),
    },
    problems: task.problems.map(({ order, problem, weight }) => ({
      ...problem,
      order,
      weight,
    })),
    startDateTime: {
      date: format(startDate, 'yyyy-MM-dd'),
      time: format(startDate, 'HH:mm:ss'),
    },
  };

  const onSubmit = (taskForm) => {
    createTask.mutate({ ...taskForm, id: taskId });
  };

  const onDelete = () => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm('Are you sure?');
    if (confirmed) {
      deleteTask.mutate();
    }
  };

  useEffect(() => {
    if (task.role === 'MEMBER') {
      history.push(`/teams/${teamId}/${taskId}`);
    }
  }, [task.role]);

  return <TaskForm task={form} onDelete={onDelete} onSubmit={onSubmit} />;
}
