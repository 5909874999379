import React, { useState } from 'react';

import { css } from '@emotion/css';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import CodeBox from 'problems/problem/CodeBox';
import DktCard from 'shared/DktCard';
import FlexLayout from 'shared/FlexLayout';
import ProblemHeader from 'problems/problem/ProblemHeader';
import RunConsole from 'problems/problem/RunConsole';

const containerStyle = css`
  margin-top: 56px;
  > div:first-child {
    width: 40%;
  }
  > div:last-child {
    margin-left: 40px;
    width: 60%;
  }
`;
const cardStyle = css`
  max-height: 640px;
  overflow-y: scroll;
`;
const codeContainerStyle = css`
  width: 100%;
`;

export default function ProblemPage({ children }) {
  const { problemId } = useParams();
  const { data: problem } = useQuery(`/problems/${problemId}`);
  const {
    difficulty, title, topic,
  } = problem;
  const firstInput = problem.testCases.length > 0 ? problem.testCases[0].input : '';
  const [userInput, setUserInput] = useState(firstInput);
  const [consoleOutput, setConsoleOutput] = useState('');

  return (
    <FlexLayout style={containerStyle}>
      <div>
        <ProblemHeader difficulty={difficulty} title={title} topic={topic} />
        <DktCard style={cardStyle}>
          {children}
        </DktCard>
      </div>
      <div className={codeContainerStyle}>
        <CodeBox setOutput={setConsoleOutput} userInput={userInput} />
        <RunConsole input={userInput} output={consoleOutput} setInput={setUserInput} />
      </div>
    </FlexLayout>
  );
}
