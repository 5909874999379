import React from 'react';

import { useMutation, useQuery } from 'react-query';
import { dikastisApi } from 'dikastis-api';
import { useHistory } from 'react-router-dom';
import { useToast } from 'toast/toast-context';

import ProblemForm from 'problems/form/ProblemForm';
import useDikastisRouting from 'router/useDikastisRouting';

export default function ProblemEditContainer() {
  const { addToast } = useToast();
  const history = useHistory();
  const { params } = useDikastisRouting();
  const { problemId } = params;
  const createProblem = useMutation(
    (problemForm) => dikastisApi.put('/problems', problemForm),
    {
      onError: ({ response }) => {
        const { status } = response;
        addToast(status);
      },
      onSuccess: () => {
        history.push(`/problems/${problemId}`);
      },
    },
  );
  const deleteProblem = useMutation(
    () => dikastisApi.delete(`/problems/${problemId}`),
    {
      onSuccess: () => {
        history.push('/problems');
      },
    },
  );
  const { data: problem } = useQuery(`/problems/${problemId}`);

  const onSubmit = (values) => {
    createProblem.mutate(values);
  };

  const onDelete = () => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm('Are you sure?');
    if (confirmed) {
      deleteProblem.mutate();
    }
  };

  return <ProblemForm problem={problem} onDelete={onDelete} onSubmit={onSubmit} />;
}
