import React from 'react';

import { Form, Formik } from 'formik';
import { css } from '@emotion/css';
import { useMutation } from 'react-query';

import { dikastisApi } from 'dikastis-api';
import { useAuth } from 'auth/auth-context';

import DktButton from 'shared/DktButton';
import FlexLayout from 'shared/FlexLayout';
import { useToast } from 'toast/toast-context';

const containerStyle = css`
  width: fit-content;
  margin: 40px auto;
`;
const buttonStyle = css`
  margin-top: 20px;
  align-self: flex-end;
`;

export default function PersonForm({
  buttonText, children, initialValues, onSubmit, validationSchema,
}) {
  const { addToast } = useToast();
  const { fetchUser } = useAuth();
  const editPerson = useMutation(
    (personForm) => dikastisApi.put('/people', personForm),
    {
      onError: ({ response }) => {
        const { status } = response;
        if (status === 403) {
          addToast(status, 'Wrong credentials o_o');
        }
        addToast(status);
      },
      onSuccess: () => {
        fetchUser();
        addToast(200, 'Information updated!');
      },
    },
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        const updateBody = onSubmit(values);
        if (updateBody) {
          editPerson.mutate(updateBody);
          resetForm();
        }
      }}
    >
      <Form>
        <FlexLayout flexDirection="column" justifyContent="center" style={containerStyle}>
          {children}
          <DktButton submit style={buttonStyle}>{buttonText}</DktButton>
        </FlexLayout>
      </Form>
    </Formik>
  );
}
