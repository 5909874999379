import React from 'react';

import { css, cx } from '@emotion/css';
import { useQuery } from 'react-query';

import DktMarkdown from 'shared/DktMarkdown';
import DktTable from 'shared/table/DktTable';
import DktText from 'shared/DktText';

const columns = [
  {
    Header: 'Question Feedback',
    accessor: 'feedbackView',
  },
];

const sectionTitleStyle = css`
  margin-top: 16px;
  font: 24px Viga, sans-serif;
  color: #172B4D;
`;

const tableStyleBase = css`
  width: 100%;
  padding: 20px 0;
`;

function Section({ content, title }) {
  return (
    <div>
      <DktText holder="p" style={sectionTitleStyle}>{title}</DktText>
      <DktMarkdown content={content} />
    </div>
  );
}

export default function FeedbackOverview({ containerStyle, feedback }) {
  const {
    authorName,
    general,
    problems,
    reply,
  } = feedback;

  const getTitle = (problemId) => {
    const { data: problem } = useQuery(`/problems/${problemId}`);
    return problem.title;
  };

  const data = (problems ?? []).map((feedbackP) => ({
    ...feedbackP,
    feedbackView: <Section content={feedbackP.text} title={getTitle(feedbackP.problemId)} />,
  }));

  return (
    <div className={containerStyle}>
      <div className={css`margin-left:32px`}>
        <DktText>
          <strong>Author: </strong>
          {authorName}
        </DktText>
        <Section content={general} title="General Feedback" />
      </div>
      {problems && (
        <DktTable
          cardStyle={cx(tableStyleBase)}
          columns={columns}
          data={data}
        />
      )}
      {reply && <Section content={reply} title="Student Reply" />}
    </div>
  );
}
