import React from 'react';

import { Form, Formik } from 'formik';
import { UlidMonotonic } from 'id128';
import { css } from '@emotion/css';

import * as yup from 'yup';
import DktButton from 'shared/DktButton';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import ProblemBodyForm from 'problems/form/ProblemBodyForm';
import ProblemTestCasesForm from 'problems/form/PorblemTestCasesForm';
import ProblemTypeForm from 'problems/form/ProblemTypeForm';

const fieldStyle = css`
  width: 100%;
`;
const buttonContainerStyle = css`
  margin-top: 20px;
`;

const SchemaValidation = yup.object().shape({
  description: yup.string().optional().min(3, 'Very short'),
  inputDescription: yup.string().optional().min(3, 'Very short'),
  organization: yup.string().optional().length(26, 'Must have 26 characters or be empty'),
  outputDescription: yup.string().optional().min(3, 'Very short'),
  timeLimit: yup.number().moreThan(0, 'At least 1ms'),
  title: yup.string().min(3, 'Very short').required('Is required'),
});

export default function ProblemForm({ onDelete, onSubmit, problem }) {
  const testCases = problem
    ? problem.testCases.map((t) => ({
      ...t,
      key: UlidMonotonic.generate().toRaw(),
    })) : [];

  const {
    description,
    difficulty,
    inputDescription,
    judgingCode,
    judgingLanguage,
    organizationId,
    outputDescription,
    problemType,
    timeLimit,
    title,
    topics,
    valid,
  } = problem || {};

  return (
    <Formik
      initialValues={{
        ...problem,
        description: description || '',
        difficulty: difficulty || 'EASY',
        inputDescription: inputDescription || '',
        judgingCode: judgingCode || '',
        judgingLanguage: judgingLanguage || 'PLAIN_TEXT',
        organizationId: organizationId || '',
        outputDescription: outputDescription || '',
        problemType: problemType || 'STATIC',
        testCases,
        timeLimit: timeLimit || 200,
        title: title || '',
        topics: topics || [],
        valid: valid || false,
      }}
      validateOnChange={false}
      validationSchema={SchemaValidation}
      onSubmit={(values) => {
        const newProblem = values;
        if (newProblem.organizationId === '') {
          newProblem.organizationId = undefined;
        }
        onSubmit(values);
      }}
    >
      <Form>
        <DktText holder="h2">New Problem</DktText>
        <ProblemBodyForm edit={onDelete} fieldStyle={fieldStyle} />
        {problem && <ProblemTypeForm problem={problem} />}
        <ProblemTestCasesForm fieldStyle={fieldStyle} />
        <FlexLayout justifyContent={onDelete ? 'space-between' : 'flex-end'} style={buttonContainerStyle}>
          {onDelete && <DktButton negative onClick={onDelete}>Delete</DktButton>}
          <DktButton submit>{problem ? 'Save' : 'Create'}</DktButton>
        </FlexLayout>
      </Form>
    </Formik>
  );
}
