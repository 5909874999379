import React from 'react';

import useDikastisRouting from 'router/useDikastisRouting';

import FlexLayout from 'shared/FlexLayout';
import TabBarItem from 'navigation/tabbar/TabBarItem';

export default function TabBar() {
  const { params, tabs, url } = useDikastisRouting();

  return (
    <FlexLayout justifyContent="center">
      {tabs.map(({ genPath, name }) => {
        const link = genPath(params);
        const selected = link === url;
        return <TabBarItem key={name} link={link} name={name} selected={selected} />;
      })}
    </FlexLayout>
  );
}
