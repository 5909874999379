import React from 'react';

import { Route } from 'react-router-dom';

import { Path } from 'router/routing';

import DktRouter from 'router/DktRouter';
import Organization from 'organizations/organization/Organization';
import OrganizationHome from 'organizations/home/OrganizationHome';
import OrganizationTask from 'organizations/task/OrganizationTask';
import OrganizationTaskFeedback from 'organizations/feedback/OrganizationTaskFeedback';
import OrganizationTaskFeedbackPerson from 'organizations/feedback/OrganizationTaskFeedbackPerson';
import OrganizationTaskPerson from 'organizations/person/OrganizationTaskPerson';
import Team from 'teams/shared/Team';

export default function OrganizationRouter() {
  return (
    <DktRouter>
      <Route exact component={OrganizationHome} path={Path.ORGANIZATIONS_HOME} />
      <Route exact component={Organization} path={Path.ORGANIZATION} />
      <Route exact component={Team} path={Path.ORGANIZATION_TEAM} />
      <Route exact component={OrganizationTask} path={Path.ORGANIZATION_TEAM_TASK} />
      <Route
        exact
        component={OrganizationTaskFeedback}
        path={Path.ORGANIZATION_TEAM_TASK_FEEDBACK}
      />
      <Route
        exact
        component={OrganizationTaskFeedbackPerson}
        path={Path.ORGANIZATION_TEAM_TASK_FEEDBACK_PERSON}
      />
      <Route exact component={OrganizationTaskPerson} path={Path.ORGANIZATION_TEAM_TASK_PERSON} />
    </DktRouter>
  );
}
