import React from 'react';

import * as yup from 'yup';
import { useAuth } from 'auth/auth-context';
import { useToast } from 'toast/toast-context';

import DktFormField from 'shared/form/DktFormField';
import PersonForm from 'person/form/PersonForm';

export default function PersonChangeEmail() {
  const { addToast } = useToast();
  const { user } = useAuth();
  const initialValues = {
    confirmEmail: '',
    email: '',
  };
  const validationSchema = yup.object().shape({
    confirmEmail: yup
      .string()
      .email('Email invalid')
      .oneOf([yup.ref('email')], "E-mail doesn't match")
      .required('Is required'),
    email: yup
      .string()
      .email('Email invalid')
      .required('Is required'),
  });

  const onSubmit = ({ confirmEmail, email }) => {
    if (email !== confirmEmail) {
      addToast(400, 'Emails are not the same :o');
      return undefined;
    }
    return { email, oldEmail: user.email };
  };

  return (
    <PersonForm buttonText="Change e-mail" initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <DktFormField name="email" title="E-mail" />
      <DktFormField name="confirmEmail" title="Confirm e-mail" />
    </PersonForm>
  );
}
