import React from 'react';

import { Link } from 'react-router-dom';
import { css } from '@emotion/css';

import optionsIcon from 'assets/options-icon.svg';

const containerStyle = css`
  padding: 0 12px;
`;

export default function OptionsIcon() {
  return (
    <Link className={containerStyle} to="/profile">
      <img alt="Options" src={optionsIcon} />
    </Link>
  );
}
