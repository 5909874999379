import React from 'react';

import ProblemPage from 'problems/problem/ProblemPage';
import ProblemSubmissions from 'problems/problem/submission/ProblemSubmissions';

export default function ProblemSubmissionPage() {
  return (
    <ProblemPage>
      <ProblemSubmissions />
    </ProblemPage>
  );
}
