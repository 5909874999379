import React from 'react';

import * as yup from 'yup';

import DktFormField from 'shared/form/DktFormField';
import PersonForm from 'person/form/PersonForm';

export default function PersonChangePassword() {
  const initialValues = {
    confirmPassword: '',
    oldPassword: '',
    password: '',
  };
  const validationSchema = yup.object().shape({
    confirmPassword: yup
      .string()
      .min(3, 'Very short')
      .max(40, 'Max is 40 characters')
      .oneOf([yup.ref('password')], "Password doesn't match")
      .required('Is required'),
    password: yup
      .string()
      .min(3, 'Very short')
      .max(40, 'Max is 40 characters')
      .required('Is required'),
  });

  const onSubmit = ({ confirmPassword, oldPassword, password }) => {
    if (password !== confirmPassword) {
      return undefined;
    }
    return { oldPassword, password };
  };

  return (
    <PersonForm buttonText="Change password" initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <DktFormField name="oldPassword" title="Old password" type="password" />
      <DktFormField name="password" title="Password" type="password" />
      <DktFormField name="confirmPassword" title="Confirm password" type="password" />
    </PersonForm>
  );
}
