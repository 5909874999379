import React from 'react';

import { Form, Formik } from 'formik';
import { css } from '@emotion/css';
import { useToast } from 'toast/toast-context';

import useDikastisRouting from 'router/useDikastisRouting';

import * as yup from 'yup';
import DktButton from 'shared/DktButton';
import DktFormField from 'shared/form/DktFormField';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import TaskProblemForm from 'teams/task/form/TaskProblemForm';
import { isBefore } from 'date-fns';

const fieldStyle = css`
  width: 100%;
`;
const buttonContainerStyle = css`
  margin-top: 40px;
`;
const noResize = css`
  width: 100%;
  resize: none;
`;
const containerStyle = css`
  margin-left: 40px;
`;

const SchemaValidation = yup.object().shape({
  addProblem: yup.string().min(26, 'Must have 26 characters').max(26, 'Must have 26 characters'),
  addProblemWeight: yup.number().moreThan(0.0, 'Must be more than 0.0').required('Is Required'),
  description: yup.string().min(3, 'Very short').required('Is required'),
  title: yup.string().min(3, 'Very short').required('Is required'),
});

function RangeDatePicker() {
  return (
    <FlexLayout>
      <FlexLayout>
        <DktFormField fieldStyle={noResize} name="startDateTime.date" title="Start date" type="date" />
        <DktFormField containerStyle={containerStyle} fieldStyle={noResize} name="startDateTime.time" title="Start time" type="time" />
      </FlexLayout>
      <FlexLayout style={containerStyle}>
        <DktFormField fieldStyle={noResize} name="endDateTime.date" title="End date" type="date" />
        <DktFormField containerStyle={containerStyle} fieldStyle={noResize} name="endDateTime.time" title="End time" type="time" />
      </FlexLayout>
    </FlexLayout>
  );
}

export default function TaskForm({ onDelete, onSubmit, task }) {
  const { addToast } = useToast();
  const { params } = useDikastisRouting();
  const { teamId } = params;

  return (
    <Formik
      initialValues={{
        addProblem: '',
        addProblemWeight: 1.0,
        description: '',
        endDateTime: {
          date: '',
          time: '',
        },
        isContest: false,
        problems: [],
        startDateTime: {
          date: '',
          time: '',
        },
        title: '',
        ...task,
      }}
      validateOnChange={false}
      validationSchema={SchemaValidation}
      onSubmit={({
        description, endDateTime, isContest, problems, startDateTime, title,
      }) => {
        const beginDate = new Date(`${startDateTime.date}T${startDateTime.time}`);
        const endDate = new Date(`${endDateTime.date}T${endDateTime.time}`);
        if (isBefore(endDate, beginDate)) {
          addToast(400);
          return;
        }
        const beginTime = beginDate.toISOString();
        const endTime = endDate.toISOString();
        const taskProblems = problems.map(({ id, weight }, idx) => ({
          order: idx,
          problem: {
            id,
          },
          weight,
        }));
        const newTask = {
          beginTime,
          description,
          endTime,
          isContest,
          problems: taskProblems,
          teamId,
          title,
        };
        onSubmit(newTask);
      }}
    >
      <Form>
        <DktText holder="h2">{task ? 'Edit Task' : 'New Task'}</DktText>
        <DktFormField fieldStyle={fieldStyle} name="title" placeholder="A beautiful list" title="Title" />
        <DktFormField fieldStyle={fieldStyle} name="description" placeholder="Code practicing" title="Description" />
        <RangeDatePicker fieldStyle={fieldStyle} />
        <DktFormField name="isContest" title="Contest" type="checkbox" />
        <TaskProblemForm fieldStyle={fieldStyle} />
        <FlexLayout justifyContent={onDelete ? 'space-between' : 'flex-end'} style={buttonContainerStyle}>
          {onDelete && <DktButton negative onClick={onDelete}>Delete</DktButton>}
          <DktButton submit>{task ? 'Save' : 'Create'}</DktButton>
        </FlexLayout>
      </Form>
    </Formik>
  );
}
