import React from 'react';

import { css } from '@emotion/css';

import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import ProblemDifficulty from 'problems/shared/ProblemDifficulty';

const problemContainerStyle = css`
  margin-bottom: 20px;
`;
const difficultyStyle = css`
  margin: 0;
`;
const topicStyle = css`
  color: #EEBF4D;
`;

export default function ProblemHeader({ difficulty, title, topic }) {
  return (
    <FlexLayout alignItems="center" justifyContent="space-between" style={problemContainerStyle}>
      <div>
        <DktText holder="h2">{title}</DktText>
        <DktText holder="h3" style={topicStyle}>{topic}</DktText>
      </div>
      <ProblemDifficulty difficulty={difficulty} style={difficultyStyle} />
    </FlexLayout>
  );
}
