import React from 'react';

import { css } from '@emotion/css';

import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

const containerStyle = css`
  padding: 40px 68px;
`;

export default function GeneralPage({ buttons, children }) {
  return (
    <FlexLayout expand flexDirection="column" style={containerStyle}>
      <FlexLayout alignItems="center" justifyContent="space-between">
        <DktText holder="h1">Dikastis</DktText>
        <div>
          {buttons}
        </div>
      </FlexLayout>
      <FlexLayout
        expand
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        {children}
      </FlexLayout>
    </FlexLayout>
  );
}
