import React from 'react';

import { css } from '@emotion/css';
import format from 'date-fns/format';
import { useQuery } from 'react-query';

import useDikastisRouting from 'router/useDikastisRouting';

import DktTable from 'shared/table/DktTable';
import DktText from 'shared/DktText';
import EditIcon from 'shared/icons/EditIcon';
import FeedbackStatus from 'problems/shared/FeedbackStatus';
import FlexLayout from 'shared/FlexLayout';

const containerStyle = css`
  margin: auto;
  margin-top: 80px;
  max-width: 1200px;
`;
const descriptionStyle = css`
  color: #EEBF4D;
`;
const tableStyle = css`
  margin-top: 20px;
`;

const feedbackStatusOrder = {
  LATE: 1,
  PENDING: 0,
  READY: 2,
  SENT: 3,
};

function parseParticipants(participants, feedbackTask, feedbacks, url) {
  return participants.map(({ person }) => ({
    getAuthor: () => {
      const feedback = feedbacks.find(
        (element) => element.receiverUsername === person.username,
      );
      if (feedback === undefined) {
        return '';
      }
      return feedback.authorName;
    },
    link: `${url}/${person.username}`,
    name: person.name,
    statusString: () => {
      const feedback = feedbacks.find(
        (element) => element.receiverUsername === person.username,
      );
      if (feedback === undefined) {
        return 'PENDING';
      }
      if (feedback.reply) {
        return 'REPLIED';
      }
      if (feedback.status === 'PENDING') {
        const currentDate = new Date();
        const now = currentDate.getTime();
        const deadlineDate = new Date(feedbackTask.deadline);
        const deadline = deadlineDate.getTime();
        return now > deadline ? 'LATE' : 'PENDING';
      }
      return feedback.status;
    },
  }));
}

export default function OrganizationTaskFeedback() {
  const { params, url } = useDikastisRouting();
  const { data: taskScoreboard } = useQuery(`/tasks/${params.taskId}/overview`);
  const { data: feedbacks } = useQuery(`/feedbacks/tasks/${params.taskId}`);
  const { data: feedbackTask } = useQuery(`/feedbacks/tasks/${params.taskId}/late`);
  const { participants, task } = taskScoreboard;
  const table = {
    columns: [
      {
        Header: 'Name',
        accessor: 'name',
        sortType: (rowA, rowB, id) => {
          const a = rowA.original[id].toLowerCase();
          const b = rowB.original[id].toLowerCase();
          return a > b ? 1 : -1;
        },
      },
      {
        Header: 'Author',
        accessor: 'author',
        sortType: (rowA, rowB, id) => {
          const a = rowA.original[id].toLowerCase();
          const b = rowB.original[id].toLowerCase();
          return a > b ? 1 : -1;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortType: (rowA, rowB, id) => {
          const a = rowA.original[id].props.status;
          const b = rowB.original[id].props.status;
          return feedbackStatusOrder[a] <= feedbackStatusOrder[b];
        },
      },
    ],
    data: parseParticipants(participants, feedbackTask, feedbacks, url),
  };

  const tableData = table.data.map((row) => ({
    ...row,
    author: row.getAuthor(),
    status: <FeedbackStatus status={row.statusString()} />,
  }));
  const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy - HH:mm');

  return (
    <div className={containerStyle}>
      <FlexLayout justifyContent="space-between">
        <div>
          <DktText holder="h2">{task.title}</DktText>
          <DktText holder="h4">{`Start: ${formatDate(task.beginTime)}`}</DktText>
          <DktText holder="h4">{`End: ${formatDate(task.endTime)}`}</DktText>
          {task.description && <DktText holder="p" style={descriptionStyle}>{task.description}</DktText>}
        </div>
        <EditIcon />
      </FlexLayout>
      <DktTable
        cardStyle={tableStyle}
        columns={table.columns}
        data={tableData}
      />
    </div>
  );
}
