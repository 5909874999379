/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { css, cx } from '@emotion/css';
import { useExpanded, useSortBy, useTable } from 'react-table';

import DktCard from 'shared/DktCard';
import TableBody from 'shared/table/TableBody';
import TableHead from 'shared/table/TableHead';

const cardStyleBase = css`
  padding: 20px 0;
`;
const tableStyle = css`
  min-width: 100%;
  table-layout: fixed;
  border-spacing: 0px;
  font-size: 24px;
  thead {
    color: #909090;
    text-align: left;
  }
  th {
    padding: 8px 0;
    min-width: 68px;
    font-weight: normal;
    text-align: center;
    > div {
      justify-content: center;
    }
  }
  td {
    font-weight: 600;
    text-overflow: clip;
  }
  a {
    display: block;
  }
  td:first-child, th:first-child {
    text-align: left;
  }
  th:first-child > div {
    justify-content: flex-start;
  }
  .content-holder {
    padding: 20px 0;
  }
  tr > td:first-child > .content-holder, th:first-child {
    padding-left: 32px;
  }
  tr > td:last-child > .content-holder, tr > th:last-child  {
    padding-right: 32px;
  }
  th:last-child {
    padding-right: 16px;
    box-sizing: border-box;
    > div {
      justify-content: center;
    }
  }
  tbody tr:hover {
    background-color: #EEE;
  }
  #expansion td {
    padding: 20px 32px;
  }
  #expansion:hover {
    background-color: inherit;
  }
`;

export default function DktTable({
  ExpandableComponent,
  cardStyle,
  columns,
  data,
  rowOnClick,
}) {
  const tableProps = useTable(
    {
      columns: React.useMemo(() => columns, []),
      data,
    },
    useSortBy,
    useExpanded,
  );

  return (
    <DktCard style={cx(cardStyleBase, cardStyle)}>
      <table {...tableProps.getTableProps()} className={tableStyle}>
        <TableHead tableProps={tableProps} />
        <TableBody
          ExpandableComponent={ExpandableComponent}
          rowOnClick={rowOnClick}
          tableProps={tableProps}
        />
      </table>
    </DktCard>
  );
}
