import React from 'react';

import { Form, Formik } from 'formik';
import { css } from '@emotion/css';

import * as yup from 'yup';
import DktButton from 'shared/DktButton';
import DktFormField from 'shared/form/DktFormField';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import MemberForm from 'teams/form/MemberForm';

const fieldStyle = css`
  width: 100%;
`;
const buttonContainerStyle = css`
  margin-top: 46px;
`;

function FormBody({ onDelete, team }) {
  return (
    <>
      <DktText holder="h2">{team ? 'Edit Team' : 'New Team'}</DktText>
      <DktFormField fieldStyle={fieldStyle} name="name" placeholder="My team name" title="Name" />
      <DktFormField fieldStyle={fieldStyle} name="organizationId" placeholder="(optional)" title="Organization ID" />
      <DktFormField as="textarea" fieldStyle={fieldStyle} name="description" placeholder="Team's description and purpose" title="Description" />
      {team && <MemberForm team={team} />}
      <FlexLayout justifyContent={team ? 'space-between' : 'flex-end'} style={buttonContainerStyle}>
        {team && <DktButton negative onClick={() => onDelete(team.id)}>Delete</DktButton>}
        <DktButton submit>{team ? 'Save' : 'Create'}</DktButton>
      </FlexLayout>
    </>
  );
}
const SchemaValidation = yup.object().shape({
  description: yup.string().min(3, 'Very short').required('Is required'),
  name: yup.string().min(3, 'Very short').required('Is required'),
  organizationId: yup.string().length(26, 'Must have 26 characters or be empty').optional(),
});

export default function TeamForm({ onDelete, onSubmit, team }) {
  return (
    <Formik
      initialValues={{
        addMember: '',
        description: '',
        name: '',
        organizationId: '',
        ...team,
      }}
      validateOnChange={false}
      validationSchema={SchemaValidation}
      onSubmit={(values) => {
        const teamForm = values;
        if (!teamForm.organizationId || teamForm.organizationId === '') {
          teamForm.organizationId = undefined;
        }
        onSubmit(teamForm);
      }}
    >
      <Form>
        <FormBody team={team} onDelete={onDelete} />
      </Form>
    </Formik>
  );
}
