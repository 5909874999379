import React from 'react';

import { css } from '@emotion/css';

const ascendingStyle = css`
  margin-left: 8px;
  transform: rotate(180deg);
`;
const descendingStyle = css`
  margin-left: 8px;
`;

export default function OrderIcon({ order }) {
  const className = order === 'ascending' ? ascendingStyle : descendingStyle;

  return (
    <svg className={className} fill="none" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.5" cy="9.5" fill="#F8B917" r="9.5" />
      <line stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" transform="matrix(-0.785803 -0.618477 0.528739 -0.848785 10.6864 11.8689)" x1="1" x2="6.87274" y1="-1" y2="-1" />
      <path d="M14.2527 7.6851L9.62494 12.1992" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  );
}
