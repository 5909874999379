import React, { useState } from 'react';

import { css } from '@emotion/css';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import DktButton from 'shared/DktButton';
import DktSearchBar from 'shared/DktSearchBar';
import DktText from 'shared/DktText';
// import EditIcon from 'shared/icons/EditIcon';
import FlexLayout from 'shared/FlexLayout';
import MemberList from 'shared/members/MemberList';
import TeamsList from 'teams/shared/TeamsList';

const barContainerStyle = css`
  margin-top: 36px;
`;
const barStyle = css`
  margin-top: 0px;
`;
// const editIconStyle = css`
//   margin-left: 170px;
//   margin-right: 20px;
// `;
const organizationContainerStyle = css`
  margin-top: 56px;
`;

export default function Organization() {
  const [search, setSearch] = useState('');
  const { organizationId } = useParams();
  const { data: organization } = useQuery(`/organizations/${organizationId}`);
  const teams = organization.teams.filter((team) => (
    team.name.toLowerCase().includes(search.toLowerCase())
    || team.id.toLowerCase().includes(search.toLowerCase())
  )).map((team) => ({ ...team, link: `/organizations/${organizationId}/${team.id}` }));

  return (
    <>
      <FlexLayout style={barContainerStyle}>
        <DktSearchBar containerStyle={barStyle} placeholder="Search by team ID or name" onKeyDown={setSearch} />
        <DktButton negative href="/teams/new">Create Team</DktButton>
      </FlexLayout>
      <FlexLayout justifyContent="space-around" style={organizationContainerStyle}>
        <div>
          <DktText holder="h2">{organization.title}</DktText>
          <TeamsList teams={teams} />
        </div>
        <div>
          {/* <EditIcon className={editIconStyle} /> */}
          <MemberList members={organization.members} />
        </div>
      </FlexLayout>
    </>
  );
}
