import React, { useState } from 'react';

import { css } from '@emotion/css';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import { useQuery } from 'react-query';

import useDikastisRouting from 'router/useDikastisRouting';

import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import ProblemBody from 'problems/shared/ProblemBody';
import ProblemList from 'problems/shared/ProblemList';
import RoundIcons from 'shared/icons/statusIcons/RoundIcons';
import SubmissionList from 'organizations/person/SubmissionList';

const containerStyle = css`
  margin: 68px auto 0;
  max-width: 1600px;
`;
const taskStyle = css`
  :last-child {
    margin-left: 20px;
  }
`;
const flexStyle = css`
  margin-top: 20px;
`;

const columns = [
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Difficulty',
    accessor: 'difficulty',
  },
  {
    Header: 'Weight',
    accessor: 'weight',
  },
];

const ExpandableComponent = ({ row }) => <ProblemBody id={row.original.id} />;

export default function DikastisOrganizationTaskPerson() {
  const { params } = useDikastisRouting();
  const { organizationId, personId, taskId } = params;
  const { data: task } = useQuery(`/tasks/${taskId}`);
  const endTime = new Date(task.endTime);
  const { data: person } = useQuery(`/people/${personId}`);
  const { data: problemSubmissionMap } = useQuery(`/organizations/${organizationId}/${taskId}/${personId}/submissions`);
  const problems = task.problems.map(({ order, problem, weight }) => {
    const status = (problemSubmissionMap[problem.id] || []).reduce((acc, submission) => {
      const date = new Date(submission.date);
      if (isBefore(date, endTime) && submission.status === 'ACCEPTED') {
        return 'SOLVED';
      }
      if (submission.status === 'ACCEPTED' && acc !== 'SOLVED') {
        return 'TRIED';
      }
      return acc;
    }, 'BLANK');
    return {
      ...problem,
      order,
      status: <RoundIcons status={status} />,
      title: `${String.fromCharCode(65 + order)}. ${problem.title}`,
      weight,
    };
  });
  const [problem, setProblem] = useState(undefined);
  const submissions = problem && problemSubmissionMap[problem.id]
    ? problemSubmissionMap[problem.id] : [];

  const rowOnClick = (row) => {
    const { problem: p } = task.problems.find(({ problem: item }) => item.id === row.original.id);
    setProblem(p);
  };

  const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy - HH:mm');

  return (
    <div className={containerStyle}>
      <DktText holder="h4">{task.title}</DktText>
      <FlexLayout>
        <DktText holder="p" style={taskStyle}>{`Start: ${formatDate(task.beginTime)}`}</DktText>
        <DktText holder="p" style={taskStyle}>{`End: ${formatDate(task.endTime)}`}</DktText>
      </FlexLayout>
      <DktText holder="h2">{person.name}</DktText>
      <FlexLayout justifyContent="center" style={flexStyle}>
        <ProblemList
          ExpandableComponent={ExpandableComponent}
          columns={columns}
          problems={problems}
          rowOnClick={rowOnClick}
        />
        <SubmissionList
          problem={problem}
          submissions={submissions}
          task={task}
        />
      </FlexLayout>
    </div>
  );
}
