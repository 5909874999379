import React from 'react';

import { css } from '@emotion/css';
import { useField } from 'formik';

import { dikastisApi } from 'dikastis-api';
import { useToast } from 'toast/toast-context';

import ClickableIcon from 'shared/icons/ClickableIcon';
import DktButton from 'shared/DktButton';
import DktFormField from 'shared/form/DktFormField';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import ProblemBody from 'problems/shared/ProblemBody';
import ProblemList from 'problems/shared/ProblemList';

import addSymbol from 'assets/add-symbol.svg';
import downArrowIcon from 'assets/down-arrow-circle.svg';
import removeIcon from 'assets/remove-cross.svg';
import upArrowIcon from 'assets/up-arrow-circle.svg';

const addButtonStyle = css`
  height: fit-content;
  padding: 8px 8px 2px;
  margin: 0 0 4px 40px;
`;
const tableStyle = css`
  margin-top: 12px;
`;
const problemFieldStyle = css`
  margin-left: 12px;
`;

const tableColumns = [
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Difficulty',
    accessor: 'difficulty',
  },
  {
    Header: 'Weight',
    accessor: 'weight',
  },
  {
    Header: '',
    accessor: 'action',
  },
];

const ExpandableComponent = ({ row }) => <ProblemBody id={row.original.id} />;

function ActionButtons({
  idx, length, moveDown, moveUp, remove,
}) {
  return (
    <FlexLayout>
      {idx < length - 1 && <ClickableIcon alt="Move down" src={downArrowIcon} onClick={moveDown} />}
      {idx > 0 && <ClickableIcon alt="Move up" src={upArrowIcon} onClick={moveUp} />}
      <ClickableIcon alt="Remove" src={removeIcon} onClick={remove} />
    </FlexLayout>
  );
}

// TODO - omg, is this at least readable???? refactor please
export default function TaskProblemForm({ fieldStyle }) {
  const [addProblemField] = useField('addProblem');
  const [addProblemWeightField] = useField('addProblemWeight');
  const { addToast } = useToast();
  const [{ value: problems }, , { setValue: setProblems }] = useField('problems');

  const problemsWithOrder = problems.map((p, idx) => {
    const remove = () => {
      setProblems(problems.filter((pr) => pr !== p));
    };
    const move = (diff) => {
      [problems[idx], problems[idx + diff]] = [problems[idx + diff], problems[idx]];
      setProblems(problems);
    };
    const moveDown = () => move(1);
    const moveUp = () => move(-1);
    return ({
      ...p,
      action: <ActionButtons
        idx={idx}
        length={problems.length}
        moveDown={moveDown}
        moveUp={moveUp}
        remove={remove}
      />,
      title: `${String.fromCharCode(65 + idx)}. ${p.title}`,
    });
  });

  const addProblem = () => {
    const { value: id } = addProblemField;
    if (id.length !== 26) {
      addToast(400);
      return;
    }
    const { value: weight } = addProblemWeightField;
    const contains = problems.find((p) => p.id === id);
    if (contains) {
      addToast(409);
      return;
    }
    dikastisApi.get(`/problems/${id}`)
      .then(({ data: problem }) => {
        const { difficulty, id: problemId, title } = problem;
        setProblems([...problems, {
          difficulty, id: problemId, title, weight,
        }]);
      }).catch(({ response }) => {
        addToast(response.status);
      });
  };

  return (
    <>
      <FlexLayout alignItems="flex-end" justifyContent="space-between">
        <DktFormField fieldStyle={fieldStyle} name="addProblem" placeholder="@problemId" title="Add problem" />
        <DktFormField containerStyle={problemFieldStyle} fieldStyle={fieldStyle} name="addProblemWeight" placeholder="1.0" title="Weight" type="number" />
        <DktButton negative style={addButtonStyle} onClick={addProblem}>
          <img alt="Add symbol" src={addSymbol} />
        </DktButton>
      </FlexLayout>
      <DktText holder="form-title">Task problems</DktText>
      <ProblemList
        ExpandableComponent={ExpandableComponent}
        columns={tableColumns}
        problems={problemsWithOrder}
        tableStyle={tableStyle}
      />
    </>
  );
}
