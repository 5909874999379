import React, { useState } from 'react';

import { css } from '@emotion/css';
import { useQuery } from 'react-query';

import DktSearchBar from 'shared/DktSearchBar';
import OrganizationBox from 'organizations/home/OrganizationBox';

const containerStyle = css`
  max-width: 880px;
  margin: auto;
  margin-top: 52px;
`;

export default function OrganizationHome() {
  const [search, setSearch] = useState('');
  const { data: organizations } = useQuery('/organizations');
  const filteredOrganizations = organizations.filter((org) => (
    org.name.toLowerCase().includes(search.toLowerCase())
  ));

  return (
    <>
      <DktSearchBar placeholder="Search by organization name" onKeyDown={setSearch} />
      <div className={containerStyle}>
        {filteredOrganizations.map((organization) => (
          <OrganizationBox key={organization.id} organization={organization} />
        ))}
      </div>
    </>
  );
}
