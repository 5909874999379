import React from 'react';

import { css } from '@emotion/css';

const commonStyle = css`
  border-radius: 10px;
  color: #FFFFFF;
  margin: auto;
  padding: 8px;
  width: fit-content;
`;

export default function TaskTotal({ completed, total }) {
  const containerStyle = () => {
    if (completed / total >= 0.7) {
      return css`
        background-color: #32965D;
        ${commonStyle}
      `;
    }
    if (completed / total >= 0.5) {
      return css`
        background-color: #ED7E40;
        ${commonStyle}
      `;
    }
    return css`
      background-color: #C20114;
      ${commonStyle}
    `;
  };

  return (
    <div className={containerStyle()}>{`${completed}/${total}`}</div>
  );
}
