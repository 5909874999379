import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Path } from 'router/routing';
import { dikastisApi } from 'dikastis-api';
import { useQueryParams } from 'router/useDikastisRouting';
import { useToast } from 'toast/toast-context';

import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

const passwordRecoveryStyle = css`
  margin-top: 20px;
`;

export default function AccountActivation() {
  const [activationMessage, setActivationMessage] = useState('Activating your account...');
  const history = useHistory();
  const { key, username } = useQueryParams();
  const { addToast } = useToast();
  const activateAccount = useMutation(
    () => dikastisApi.patch(`/people/activate/${username}/${key}`),
    {
      onError: () => {
        addToast(500);
      },
      onSuccess: () => {
        setInterval(() => setActivationMessage('Account activated!'), 2000);
        setInterval(() => history.push(Path.SIGN_IN), 7000);
      },
    },
  );

  useEffect(() => {
    activateAccount.mutate();
  }, [username, key]);

  return (
    <FlexLayout alignItems="center" flexDirection="column" justifyContent="center">
      <DktText holder="h1">Yay! Welcome to our platform.</DktText>
      <DktText holder="h3">{activationMessage}</DktText>
      <DktText holder="p" style={passwordRecoveryStyle}>
        You&apos;re gonna be redirected to our sign in page soon...
      </DktText>
    </FlexLayout>
  );
}
