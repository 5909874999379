import React, { useEffect } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { dikastisApi } from 'dikastis-api';
import useDikastisRouting from 'router/useDikastisRouting';

import TeamForm from 'teams/form/TeamForm';

export default function TeamEditContainer() {
  const history = useHistory();
  const { params } = useDikastisRouting();
  const { teamId } = params;
  const { data: team } = useQuery(`/teams/${teamId}`);
  const mutation = useMutation(
    (teamForm) => dikastisApi.put('/teams', teamForm),
    {
      onSuccess: () => {
        history.push(`/teams/${team.id}`);
      },
    },
  );
  const deleteMutation = useMutation(
    (id) => dikastisApi.delete(`/teams/${id}`),
    {
      onSuccess: () => {
        history.push('/teams');
      },
    },
  );

  const onSubmit = ({
    description, id, name, organizationId,
  }) => {
    const orgId = organizationId === '' ? null : organizationId;
    mutation.mutate({
      description, id, name, organizationId: orgId,
    });
  };

  const onDelete = (id) => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm('Are you sure?');
    if (confirmed) {
      deleteMutation.mutate(id);
    }
  };

  useEffect(() => {
    if (team.role === 'MEMBER') {
      history.push(`/teams/${team.id}`);
    }
  }, [team.role]);

  return (
    <TeamForm team={team} onDelete={onDelete} onSubmit={onSubmit} />
  );
}
