import React from 'react';

import { Route } from 'react-router-dom';

import { Path } from 'router/routing';

import DktRouter from 'router/DktRouter';
import ProblemBodyPage from 'problems/ProblemBodyPage';
import ProblemCreationContainer from 'problems/ProblemCreationContainer';
import ProblemEditContainer from 'problems/ProblemEditContainer';
import ProblemListPage from 'problems/ProblemListPage';
import ProblemSubmissionPage from 'problems/ProblemSubmissionPage';

export default function ProblemsRouter() {
  return (
    <DktRouter>
      <Route exact component={ProblemListPage} path={Path.PROBLEMS} />
      <Route exact component={ProblemListPage} path={Path.PROBLEMS_MINE} />
      <Route exact component={ProblemCreationContainer} path={Path.PROBLEMS_NEW} />
      <Route exact component={ProblemBodyPage} path={Path.PROBLEMS_PROBLEM} />
      <Route exact component={ProblemEditContainer} path={Path.PROBLEMS_PROBLEM_EDIT} />
      <Route exact component={ProblemSubmissionPage} path={Path.PROBLEMS_PROBLEM_SUBMISSIONS} />
    </DktRouter>
  );
}
