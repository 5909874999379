import React from 'react';

import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import { dikastisApi } from 'dikastis-api';

import TeamForm from 'teams/form/TeamForm';

export default function TeamCreationContainer() {
  const history = useHistory();
  const mutation = useMutation(
    (team) => dikastisApi.post('/teams', team),
    {
      onSuccess: ({ data: team }) => {
        history.push(`/teams/${team.id}`);
      },
    },
  );

  const onSubmit = ({ description, name, organizationId }) => {
    const orgId = organizationId === '' ? null : organizationId;
    mutation.mutate({ description, name, organizationId: orgId });
  };

  return (
    <TeamForm onSubmit={onSubmit} />
  );
}
