import React, { useState } from 'react';

import { css } from '@emotion/css';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { getCode, saveCode } from 'problems/code-storage';
import { dikastisApi } from 'dikastis-api';
import useSubmission from 'hooks/useSubmission';
import { useToast } from 'toast/toast-context';

import DktAce from 'shared/DktAce';
import DktButton from 'shared/DktButton';
import DktCard from 'shared/DktCard';
import FlexLayout from 'shared/FlexLayout';

const selectStyle = css`
  min-width: 180px;
  padding-left: 12px;
  background-color: #FFF;
  border: 1px solid #172B4D;
  box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  font: 20px Raleway, sans-serif;
  font-weight: 500;
`;
const runSubmitStyle = css`
  > button:last-child {
    margin-left: 20px;
  }
`;
const editorStyle = css`
  margin: 20px auto;
  padding: 0;
  box-sizing: border-box;
  > div > div {
    border-radius: 10px;
  }
`;

export default function CodeBox({ setOutput, userInput }) {
  const { addToast } = useToast();
  const { fetchSubmissionResult } = useSubmission();
  const { problemId } = useParams();
  const [code, setCode] = useState(getCode(problemId));
  const [language, setLanguage] = useState('PYTHON3');
  const mutation = useMutation(
    (submission) => dikastisApi.post('/submissions', submission),
    {
      onSuccess: ({ data }, { type }) => {
        const submissionId = data.id;
        fetchSubmissionResult(submissionId).then((submissionStatus) => {
          if (!submissionStatus) {
            addToast(500);
            return;
          }
          if (type === 'EXECUTION') {
            const { outputs } = submissionStatus;
            setOutput(outputs[0].output);
            addToast('FINISHED', 'Checkout output console ;)');
          } else {
            addToast(submissionStatus.status);
          }
        });
      },
    },
  );

  const submit = (type, userTestCases) => {
    if (code === '') {
      addToast(400, 'Code cannot be empty');
      return;
    }
    if (type === 'EXECUTION') {
      mutation.mutate({
        code, language, problemId, type, userTestCases,
      });
      addToast('RUNNING');
    } else {
      mutation.mutate({
        code, language, problemId, type,
      });
      addToast('JUDGING');
    }
  };

  const onChange = (value) => {
    saveCode(problemId, value);
    setCode(value);
  };

  return (
    <>
      <FlexLayout justifyContent="space-between">
        <select className={selectStyle} onChange={({ target }) => setLanguage(target.value)}>
          <option value="PYTHON3">Python 3</option>
          <option value="HASKELL">Haskell</option>
          <option value="JAVA8">Java 1.8</option>
        </select>
        <div className={runSubmitStyle}>
          <DktButton negative onClick={() => submit('EXECUTION', [userInput])}>Run</DktButton>
          <DktButton onClick={() => submit('SUBMISSION')}>Submit</DktButton>
        </div>
      </FlexLayout>
      <DktCard style={editorStyle}>
        <DktAce code={code} id="problem-editor" language={language} onChange={onChange} />
      </DktCard>
    </>
  );
}
