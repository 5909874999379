import React from 'react';

import { css, cx } from '@emotion/css';
import { Link } from 'react-router-dom';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import isBefore from 'date-fns/isBefore';

import DktCard from 'shared/DktCard';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import ListWithDivider from 'shared/ListWithDivider';
import RoundIcons from 'shared/icons/statusIcons/RoundIcons';

const cardStyleBase = css`
  padding: 20px;
  height: fit-content;
  min-width: 400px;
`;
const labelStyle = css`
  width: 112px;
  height: 24px;
  color: #FFF;
  border-radius: 10px;
  margin-left: 8px;
`;
const redStyle = css`
  background-color: #C20114;
`;
const greenStyle = css`
  background-color: #32965D;
`;
const orangeStyle = css`
  background-color: #ED7E40;
`;
const taskStyle = css`
  padding: 20px 12px;
  box-sizing: border-box;
  cursor: pointer;
  :hover {
    background-color: #EEE;
  }
`;

function getRemainingLabelColor(endTime) {
  const diff = differenceInDays(new Date(endTime), new Date());
  if (diff <= 1) {
    return redStyle;
  }
  if (diff <= 5) {
    return orangeStyle;
  }
  return greenStyle;
}

function Task({ task }) {
  const remainigTime = formatDistanceStrict(new Date(), new Date(task.endTime));
  const isOpen = isBefore(new Date(), new Date(task.endTime));
  const { endTime, status } = task;
  const labelColor = getRemainingLabelColor(endTime);

  return (
    <Link to={task.link}>
      <FlexLayout alignItems="center" justifyContent="space-between" style={taskStyle}>
        <FlexLayout alignItems="center" justifyContent="space-between">
          <DktText holder="h3">{task.title}</DktText>
          <RoundIcons status={status} />
        </FlexLayout>
        {isOpen && <FlexLayout alignItems="center" justifyContent="center" style={cx(labelStyle, labelColor)}>{remainigTime}</FlexLayout>}
      </FlexLayout>
    </Link>
  );
}

export default function TaskList({ cardStyle, tasks }) {
  return (
    <DktCard style={cx(cardStyleBase, cardStyle)}>
      <ListWithDivider
        collection={tasks}
        mapFunc={(task) => (<Task task={task} />)}
      />
    </DktCard>
  );
}
