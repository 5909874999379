import React, { useState } from 'react';

import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { css } from '@emotion/css';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';

import { dikastisApi } from 'dikastis-api';
import useDikastisRouting from 'router/useDikastisRouting';
import { useToast } from 'toast/toast-context';

import DktButton from 'shared/DktButton';
import DktFormField from 'shared/form/DktFormField';
import DktText from 'shared/DktText';
import FeedbackOverview from 'shared/feedbacks/FeedbackOverview';
import FlexLayout from 'shared/FlexLayout';
import ProblemBody from 'problems/shared/ProblemBody';
import ProblemList from 'problems/shared/ProblemList';
import RoundIcons from 'shared/icons/statusIcons/RoundIcons';
import SubmissionList from 'organizations/person/SubmissionList';

const containerStyle = css`
  margin: 68px auto 0;
  max-width: 1600px;
`;
const notReadyStyle = css`
  height: 100%;
`;
const taskStyle = css`
  :last-child {
    margin-left: 20px;
  }
`;
const flexStyle = css`
  margin-top: 20px;
`;

const columns = [
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Difficulty',
    accessor: 'difficulty',
  },
  {
    Header: 'Weight',
    accessor: 'weight',
  },
];

const SchemaValidation = yup.object().shape({
  general: yup.string().optional().min(3, 'Very short'),
  problemText: yup.string().optional().min(3, 'Very short'),
});

const ExpandableComponent = ({ row }) => <ProblemBody id={row.original.id} />;

export default function DikastisOrganizationTaskPerson() {
  const { addToast } = useToast();
  const { params } = useDikastisRouting();
  const { organizationId, personId, taskId } = params;
  const { data: task } = useQuery(`/tasks/${taskId}`);
  const { data: person } = useQuery(`/people/${personId}`);
  const { data: feedback, refetch: refetchFeedback } = useQuery(
    `/feedbacks/tasks/${taskId}/${person.id}`,
    async () => {
      const response = await dikastisApi.get(`/feedbacks/tasks/${taskId}/${person.id}`).catch((err) => err.response);
      if (response.status === 404) {
        return {
          draft: true, general: '', notCreated: true, status: 'PENDING',
        };
      }
      return response.data;
    },
  );
  const endTime = new Date(task.endTime);
  const { data: problemSubmissionMap } = useQuery(`/organizations/${organizationId}/${taskId}/${personId}/submissions`);
  const problems = task.problems.map(({ order, problem, weight }) => {
    const status = (problemSubmissionMap[problem.id] || []).reduce((acc, submission) => {
      const date = new Date(submission.date);
      if (isBefore(date, endTime) && submission.status === 'ACCEPTED') {
        return 'SOLVED';
      }
      if (submission.status === 'ACCEPTED' && acc !== 'SOLVED') {
        return 'TRIED';
      }
      return acc;
    }, 'BLANK');
    return {
      ...problem,
      order,
      status: <RoundIcons status={status} />,
      title: `${String.fromCharCode(65 + order)}. ${problem.title}`,
      weight,
    };
  });

  const {
    general,
  } = feedback || {};
  const [problem, setProblem] = useState(undefined);
  const submissions = problem && problemSubmissionMap[problem.id]
    ? problemSubmissionMap[problem.id] : [];

  const [selectedProblemIdx, setSelectedProblemIdx] = useState(0);

  const rowOnClick = (row) => {
    const { problem: p } = task.problems.find(({ problem: item }) => item.id === row.original.id);
    setSelectedProblemIdx(row.index);
    setProblem(p);
  };

  const saveDraft = useMutation(
    (feedbackRequest) => dikastisApi.put('/feedbacks', feedbackRequest),
    {
      onSuccess: () => {
        addToast(200, 'Your feedback was saved');
        refetchFeedback();
      },
    },
  );

  const createFeedback = (feedbackRequest) => {
    dikastisApi.post('/feedbacks', feedbackRequest).then(() => {
      addToast(200, 'Your feedback was create');
      refetchFeedback();
    });
  };

  const [ready, setReady] = useState(feedback.status === 'READY');
  const setStatus = (status) => {
    if (status === 'PENDING') {
      dikastisApi.patch(`/feedbacks/${feedback.id}/ready`)
        .then(() => {
          addToast(200, 'Your feedback is now ready');
          setReady(true);
          refetchFeedback();
        });
    } else {
      dikastisApi.patch(`/feedbacks/${feedback.id}/send`)
        .then(() => {
          addToast(200, 'Feedback sent');
          refetchFeedback();
        });
    }
  };

  const sent = feedback.status === 'SENT';

  const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy - HH:mm');

  const feedbackCreation = { problems: {}, receiverId: person.id, taskId: task.id };

  if (feedback.notCreated) {
    return (
      <FlexLayout alignItems="center" flexDirection="column" justifyContent="center" style={notReadyStyle}>
        <DktText holder="h3">Before you start to write this feedback, make sure you are using your personal account instead of an organization account. </DktText>
        <br />
        <DktText holder="h3"> When you click the button below, we will assign you as the author of this feedback. </DktText>
        <br />
        <DktButton negative onClick={() => { createFeedback(feedbackCreation); }}>
          Start now!
        </DktButton>
      </FlexLayout>
    );
  }
  return (
    <div className={containerStyle}>
      <Formik
        initialValues={{
          ...feedback,
          general: general || '',
          problems: feedback.problems && feedback.problems.length > 0
            ? problems.map((problemItem) => ({
              id: problemItem.id,
              text: feedback.problems.find((feedbackProblem) => feedbackProblem.problemId === problemItem.id).text || '',
            })) : problems.map((problemItem) => ({ id: problemItem.id, text: '' })),
        }}
        validateOnChange={false}
        validationSchema={SchemaValidation}
        onSubmit={(values) => {
          const feedbackRequest = {
            general: values.general,
            id: feedback.id,
            problems: values.problems,
            receiverId: person.id,
            taskId: task.id,
          };
          saveDraft.mutate(feedbackRequest);
        }}
      >
        <Form>
          <DktText holder="h4">{task.title}</DktText>
          <FlexLayout>
            <DktText holder="p" style={taskStyle}>{`Start: ${formatDate(task.beginTime)}`}</DktText>
            <DktText holder="p" style={taskStyle}>{`End: ${formatDate(task.endTime)}`}</DktText>
          </FlexLayout>
          <DktText holder="h2">{person.name}</DktText>
          <DktText>
            <strong>Author: </strong>
            {feedback.authorName}
          </DktText>
          <FlexLayout justifyContent="flex-end">
            {/* <DktButton style={css`margin:4px`}>?</DktButton> */}
            {!sent && <DktButton negative submit style={css`margin:4px`}>Save</DktButton>}
            {!sent && !feedback.draft && <DktButton style={css`margin:4px`} onClick={() => { setStatus(feedback.status); }}>{ready ? 'Send' : 'Ready'}</DktButton>}
          </FlexLayout>
          <FlexLayout justifyContent="space-between" style={flexStyle}>
            <div className={css`width:100%`}>
              {!sent && <DktFormField name="general" placeholder="General feedback" title="General Feedback" type="preview" />}
              <ProblemList
                ExpandableComponent={ExpandableComponent}
                columns={columns}
                problems={problems}
                rowOnClick={rowOnClick}
              />
            </div>
            <div className={css`width:100%`}>
              {!sent && <DktFormField containerStyle={css`margin-left:32px`} name={`problems.${selectedProblemIdx}.text`} placeholder="Problem feedback" title="Problem Feedback" type="preview" />}
              {!sent && (
              <SubmissionList
                width
                problem={problem}
                submissions={submissions}
                task={task}
              />
              )}
              {sent && (
              <FeedbackOverview
                containerStyle={css`margin-left:32px`}
                feedback={feedback}
              />
              )}
            </div>
          </FlexLayout>
        </Form>
      </Formik>
    </div>
  );
}
