import React from 'react';

import { Link } from 'react-router-dom';
import { css } from '@emotion/css';
import format from 'date-fns/format';
import { useQuery } from 'react-query';

import useDikastisRouting from 'router/useDikastisRouting';

import DktTable from 'shared/table/DktTable';
import DktText from 'shared/DktText';
import EditIcon from 'shared/icons/EditIcon';
import FlexLayout from 'shared/FlexLayout';
import TaskTotal from 'organizations/task/TaskTotal';

const containerStyle = css`
  margin: auto;
  margin-top: 80px;
  max-width: 1200px;
`;
const descriptionStyle = css`
  color: #EEBF4D;
`;
const tableStyle = css`
  margin-top: 20px;
`;
const editIconStyle = css` 
  margin-left: 20px;
`;

function parseProblem(problems, taskProblem) {
  const letter = String.fromCharCode(65 + taskProblem.order);
  const { problem } = taskProblem;
  const prefix = problem.status === 'SOLVED' ? '+' : '';
  return { ...problems, [letter]: prefix + problem.tries };
}

function parseParticipants(participants, task, url) {
  return participants.map(({ person, problems }) => ({
    link: `${url}/${person.username}`,
    name: person.name,
    total: {
      completed: problems.filter(({ problem }) => problem.status === 'SOLVED').length,
      total: task.problems.length,
    },
    ...problems.filter(({ problem }) => problem.tries !== 0).reduce(parseProblem, {}),
  }));
}

export default function OrganizationTask() {
  const { params, url } = useDikastisRouting();
  const { data: taskScoreboard } = useQuery(`/tasks/${params.taskId}/overview`);
  const { participants, task } = taskScoreboard;
  const table = {
    columns: [
      {
        Header: 'Name',
        accessor: 'name',
        sortType: (rowA, rowB, id) => {
          const a = rowA.original[id].toLowerCase();
          const b = rowB.original[id].toLowerCase();
          return a > b ? 1 : -1;
        },
      },
      ...task.problems.map(({ order }) => ({
        Header: String.fromCharCode(65 + order),
        accessor: String.fromCharCode(65 + order),
        sortType: (rowA, rowB, id) => {
          const getNum = (s) => {
            if (!s) return 0;
            return s[0] === '+' ? parseInt(s.substring(1), 10) : s * (-1);
          };
          const a = getNum(rowA.original[id]);
          const b = getNum(rowB.original[id]);
          return a < b ? 1 : -1;
        },
      })),
      {
        Header: 'Total',
        accessor: 'total',
        sortType: (rowA, rowB, id) => {
          const a = rowA.original[id].props.completed;
          const b = rowB.original[id].props.completed;
          return a < b ? 1 : -1;
        },
      },
    ],
    data: parseParticipants(participants, task, url),
  };
  const tableData = table.data.map((row) => ({
    ...row,
    total: <TaskTotal completed={row.total.completed} total={row.total.total} />,
  }));

  const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy - HH:mm');

  return (
    <div className={containerStyle}>
      <FlexLayout justifyContent="space-between">
        <div>
          <DktText holder="h2">{task.title}</DktText>
          <DktText holder="h4">{`Start: ${formatDate(task.beginTime)}`}</DktText>
          <DktText holder="h4">{`End: ${formatDate(task.endTime)}`}</DktText>
          {task.description && <DktText holder="p" style={descriptionStyle}>{task.description}</DktText>}
        </div>
        {task.role !== 'MEMBER' && (
          <Link to={`/teams/${params.teamId}/${params.taskId}/edit`}>
            <EditIcon className={editIconStyle} />
          </Link>
        )}
      </FlexLayout>
      <DktTable
        cardStyle={tableStyle}
        columns={table.columns}
        data={tableData}
      />
    </div>
  );
}
