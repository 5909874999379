import React, { useState } from 'react';

import { css } from '@emotion/css';

import DktText from 'shared/DktText';
import StatusList from 'organizations/person/StatusList';
import SubmissionOutput from 'organizations/person/SubmissionOutput';

const containerStyle = css`
  margin-top: 40px;
`;

export default function SubmissionOutputList({ outputs }) {
  if (!outputs) return <div />;
  const [output, selectOutput] = useState(undefined);

  const select = (item) => {
    if (item === output) selectOutput(undefined);
    else selectOutput(item);
  };

  return (
    <div className={containerStyle}>
      <DktText holder="h3">Test Cases</DktText>
      <StatusList list={outputs} select={select} selectedItem={output} />
      {output && <SubmissionOutput submissionOutput={output} />}
    </div>
  );
}
