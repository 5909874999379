import React from 'react';

import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { css, cx } from '@emotion/css';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Path } from 'router/routing';
import { dikastisApi } from 'dikastis-api';
import { useQueryParams } from 'router/useDikastisRouting';
import { useToast } from 'toast/toast-context';

import AuthPage from 'auth/AuthPage';
import DktButton from 'shared/DktButton';
import DktFormField from 'shared/form/DktFormField';
import FlexLayout from 'shared/FlexLayout';

const signUpStyle = css`
  margin-top: 40px;
`;
const formStyle = css`
  display: flex;
  flex-direction: column;
`;
const fieldStyle = css`
  width: 100%;
`;

const validationSchema = yup.object().shape({
  confirmNewPassword: yup
    .string()
    .min(3, 'Very short')
    .max(40, 'Max is 40 characters')
    .oneOf([yup.ref('newPassword')], "Password doesn't match")
    .required('Is required'),
  newPassword: yup
    .string()
    .min(3, 'Very short')
    .max(40, 'Max is 40 characters')
    .required('Is required'),
});

function SignUpForm({ children }) {
  const { key, user } = useQueryParams();
  const { addToast } = useToast();
  const history = useHistory();
  const resetPassword = useMutation(
    (passwordReset) => dikastisApi.patch('/people/password-recovery', passwordReset),
    {
      onError: () => {
        addToast(500);
      },
      onSuccess: () => {
        addToast(200, 'Just sign in now!');
        history.push(Path.SIGN_IN);
      },
    },
  );

  return (
    <Formik
      initialValues={{
        confirmNewPassword: '',
        key,
        newPassword: '',
        username: user,
      }}
      validationSchema={validationSchema}
      onSubmit={resetPassword.mutate}
    >
      <Form className={cx(formStyle)}>{children}</Form>
    </Formik>
  );
}

export default function SignUp() {
  const { user } = useQueryParams();

  return (
    <AuthPage greeting={`Change your password, ${user}!`}>
      <FlexLayout flexDirection="column" justifyContent="center">
        <SignUpForm>
          <DktFormField fieldStyle={fieldStyle} name="newPassword" placeholder="Your new password" title="New Password" type="password" />
          <DktFormField fieldStyle={fieldStyle} name="confirmNewPassword" placeholder="Repeat your new password" title="Confirm New Password" type="password" />
          <FlexLayout justifyContent="flex-end">
            <DktButton submit style={signUpStyle}>Change password</DktButton>
          </FlexLayout>
        </SignUpForm>
      </FlexLayout>
    </AuthPage>
  );
}
