import React from 'react';

import { css } from '@emotion/css';

import { Domain } from 'shared/dikastis-constants';
import { DomainEntryPoint } from 'router/routing';
import { useAuth } from 'auth/auth-context';
import useDikastisRouting from 'router/useDikastisRouting';

import DktButton from 'shared/DktButton';
import FlexLayout from 'shared/FlexLayout';
import HomeIcon from 'shared/icons/HomeIcon';
import LogoutIcon from 'shared/icons/LogoutIcon';
import NavBarItem from 'navigation/navbar/NavBarItem';
import ProblemsIcon from 'shared/icons/ProblemsIcon';
import TeacherIcon from 'shared/icons/TeacherIcon';
import TeamsIcon from 'shared/icons/TeamsIcon';

import logo from 'assets/d_escuro.png';

const navStyle = css`
  border-radius: 0px 10px 10px 0px;
  box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.08);
  width: 100px;
  height: 100%;
`;
const logoStyle = css`
  margin-top: 30px;
  height: 100px;
`;
const logoutStyle = css`
  margin-bottom: 30px;
  height: 100px;
`;

const icons = Object.entries({
  [Domain.HOME]: HomeIcon,
  [Domain.PROBLEMS]: ProblemsIcon,
  [Domain.TEAMS]: TeamsIcon,
  [Domain.ORGANIZATIONS]: TeacherIcon,
});

function renderIcons(domain) {
  return icons.map(([iconDomain, Icon]) => (
    <NavBarItem
      key={iconDomain}
      Icon={Icon}
      path={DomainEntryPoint[iconDomain]}
      selected={iconDomain === domain}
    />
  ));
}

export default function Navbar() {
  const { domain } = useDikastisRouting();
  const { logout } = useAuth();

  return (
    <nav>
      <FlexLayout
        alignItems="center"
        flexDirection="column"
        justifyContent="space-between"
        style={navStyle}
      >
        <FlexLayout alignItems="center" justifyContent="center" style={logoStyle}>
          <img alt="Logo" src={logo} width="60%" />
        </FlexLayout>
        { renderIcons(domain) }
        <FlexLayout alignItems="center" justifyContent="center" style={logoutStyle}>
          <DktButton invisible onClick={logout}>
            <LogoutIcon fill="#909090" />
          </DktButton>
        </FlexLayout>
      </FlexLayout>
    </nav>
  );
}
