/* eslint-disable no-param-reassign */
import { QueryClient } from 'react-query';
import axios from 'axios';

import * as auth from 'auth/auth-provider';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
    || window.location.hostname === '[::1]'
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

let baseURL = `${window.location.protocol}//${window.location.hostname}:8089/api`;

if (!isLocalhost) {
  baseURL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/api`;
}

const dikastisApi = axios.create({
  baseURL,
});

dikastisApi.interceptors.request.use((config) => {
  const { token } = auth.getAuthData();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const defaultQueryFn = async ({ queryKey }) => {
  const { data } = await dikastisApi.get(queryKey[0]);
  return data;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      staleTime: 1000 * 60 * 10,
      suspense: true,
    },
  },
});

export { dikastisApi, queryClient };
