import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import ListWithDivider from 'shared/ListWithDivider';
import SubmissionHeader from 'problems/problem/submission/SubmissionHeader';
import SubmissionsBody from 'problems/problem/submission/SubmissionsBody';
import { useQuery } from 'react-query';

export default function ProblemSubmissions() {
  const { problemId } = useParams();
  const { data: submissions } = useQuery(`/submissions/problems/${problemId}`);
  const [selected, select] = useState(undefined);

  return (
    <ListWithDivider
      collection={submissions}
      mapFunc={(submission) => (
        <div role="presentation" onClick={() => select(submission)}>
          <SubmissionHeader submission={submission} />
          {selected === submission && <SubmissionsBody submission={submission} />}
        </div>
      )}
    />
  );
}
