import React from 'react';

export default function SearchIcon({ className }) {
  return (
    <svg className={className} fill="none" height="23" viewBox="0 0 22 23" width="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.9248 18.1201C10.8779 18.1201 12.6846 17.4731 14.1494 16.3989L19.8501 22.1118C20.0698 22.3315 20.375 22.4536 20.6924 22.4536C21.376 22.4536 21.8398 21.9287 21.8398 21.2695C21.8398 20.9644 21.7422 20.6714 21.5103 20.4517L15.834 14.751C17.0059 13.2617 17.7139 11.3696 17.7139 9.33105C17.7139 4.49707 13.7588 0.541992 8.9248 0.541992C4.09082 0.541992 0.135742 4.49707 0.135742 9.33105C0.135742 14.165 4.09082 18.1201 8.9248 18.1201ZM8.9248 16.4844C5.00635 16.4844 1.78369 13.2495 1.78369 9.33105C1.78369 5.4126 5.00635 2.18994 8.9248 2.18994C12.8433 2.18994 16.0781 5.4126 16.0781 9.33105C16.0781 13.2495 12.8433 16.4844 8.9248 16.4844Z"
        fill="#172B4D"
      />
    </svg>
  );
}
