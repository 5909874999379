import React from 'react';

import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { css, cx } from '@emotion/css';
import { useHistory } from 'react-router-dom';
import { useToast } from 'toast/toast-context';

import { useAuth } from 'auth/auth-context';

import AuthPage from 'auth/AuthPage';
import DktButton from 'shared/DktButton';
import DktFormField from 'shared/form/DktFormField';
import FlexLayout from 'shared/FlexLayout';

const signUpStyle = css`
  margin-top: 40px;
`;
const formStyle = css`
  display: flex;
  flex-direction: column;
`;
const fieldStyle = css`
  width: 100%;
`;

const validationSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .min(3, 'Very short')
    .max(40, 'Max is 40 characters')
    .oneOf([yup.ref('password')], "Password doesn't match")
    .required('Is required'),
  email: yup.string().email('Email invalid').required('Is required'),
  name: yup.string().min(3, 'Very short').required('Is required'),
  password: yup
    .string()
    .min(3, 'Very short')
    .max(40, 'Max is 40 characters')
    .required('Is required'),
  username: yup
    .string()
    .min(2, 'Very short')
    .max(20, 'Max is 20 characters')
    .matches(
      /^[a-z0-9_]*$/,
      'Only alphanumeric characters and underscore allowed',
    )
    .required('Is required'),
});

function SignUpForm({ children }) {
  const { addToast } = useToast();
  const { register } = useAuth();
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        confirmPassword: '',
        email: '',
        name: '',
        password: '',
        username: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => register(values, () => {
        addToast(200, 'User created, check your e-mail :D');
        history.push('/sign-in');
      })}
    >
      <Form className={cx(formStyle)}>{children}</Form>
    </Formik>
  );
}

export default function SignUp() {
  return (
    <AuthPage greeting="Join us!">
      <FlexLayout flexDirection="column" justifyContent="center">
        <SignUpForm>
          <DktFormField fieldStyle={fieldStyle} name="name" placeholder="Your name" title="Full Name" />
          <DktFormField fieldStyle={fieldStyle} name="username" placeholder="Your username" title="Username" />
          <DktFormField fieldStyle={fieldStyle} name="email" placeholder="Your e-mail address" title="Email" type="email" />
          <DktFormField fieldStyle={fieldStyle} name="password" placeholder="Your password" title="Password" type="password" />
          <DktFormField fieldStyle={fieldStyle} name="confirmPassword" placeholder="Repeat your password" title="Confirm Password" type="password" />
          <FlexLayout justifyContent="flex-end">
            <DktButton submit style={signUpStyle}>Create Account</DktButton>
          </FlexLayout>
        </SignUpForm>
      </FlexLayout>
    </AuthPage>
  );
}
