/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import FlexLayout from 'shared/FlexLayout';
import OrderIcon from 'shared/icons/OrderIcon';

export default function TableHead({ tableProps }) {
  const { headerGroups } = tableProps;

  const sortedIcon = (sortedDesc) => {
    if (sortedDesc) {
      return <OrderIcon order="descending" />;
    }
    return <OrderIcon order="ascending" />;
  };

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
              <FlexLayout>
                {column.render('Header')}
                <span>
                  {column.isSorted ? sortedIcon(column.isSortedDesc) : ''}
                </span>
              </FlexLayout>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}
