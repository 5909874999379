import React from 'react';

import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { css, cx } from '@emotion/css';

import { useAuth } from 'auth/auth-context';

import AuthPage from 'auth/AuthPage';
import DktButton from 'shared/DktButton';
import DktFormField from 'shared/form/DktFormField';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';
import { Link } from 'react-router-dom';
import { Path } from 'router/routing';

const formStyle = css`
  display: flex;
  flex-direction: column;
`;
const signInStyle = css`
  margin-top: 40px;
`;
const fieldStyle = css`
  width: 100%;
`;
const passwordRecoveryStyle = css`
  margin-top: 20px;
`;

const validationSchema = yup.object().shape({
  password: yup.string().min(4, 'Very short').required('Is required'),
  username: yup
    .string()
    .min(2, 'Very short')
    .matches(
      /^[a-z0-9_]*$/,
      'Only alphanumeric characters and underscore allowed',
    )
    .required('Is required'),
});

function SignInForm({ children }) {
  const { login } = useAuth();

  return (
    <Formik
      initialValues={{
        password: '',
        username: '',
      }}
      validationSchema={validationSchema}
      onSubmit={login}
    >
      <Form className={cx(formStyle)}>{children}</Form>
    </Formik>
  );
}

export default function SignIn() {
  return (
    <AuthPage greeting="Welcome">
      <FlexLayout flexDirection="column" justifyContent="center">
        <SignInForm>
          <DktFormField fieldStyle={fieldStyle} name="username" placeholder="Your user" title="Username" />
          <DktFormField fieldStyle={fieldStyle} name="password" placeholder="Your password" title="Password" type="password" />
          <DktText holder="underlined" style={passwordRecoveryStyle}>
            <Link to={Path.PASSWORD_RECOVERY_REQUEST}>Forgot your password?</Link>
          </DktText>
          <FlexLayout justifyContent="flex-end">
            <DktButton submit style={signInStyle}>
              Sign in
            </DktButton>
          </FlexLayout>
        </SignInForm>
      </FlexLayout>
    </AuthPage>
  );
}
