import React, { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import ReactAce from 'react-ace-editor';
import brace from 'brace';

import 'brace/ext/language_tools';
import 'brace/ext/searchbox';
import 'brace/mode/python';
import 'brace/mode/haskell';
import 'brace/mode/java';

const styleBase = css`
  .ace_editor {
    font: 14px monospace;
  }
  .ace-tomorrow .ace_gutter {
    background-color: #EEBF4D;
  }
  .ace-tomorrow .ace_gutter-active-line {
    background-color: #f8d071;
  }
  .ace-tomorrow .ace_marker-layer .ace_active-line {
    background-color: #fdeabd;
  }
`;

const parseLanguage = {
  HASKELL: 'haskell',
  JAVA8: 'java',
  PYTHON3: 'python',
  undefined: 'python',
};

export default function DktAce({
  code = '',
  id = 'ace-editor',
  language,
  onChange,
  readOnly,
  style,
}) {
  useEffect(() => {
    const { edit } = brace;
    const editor = edit(id);
    editor.getSession().setMode(`ace/mode/${parseLanguage[language]}`);
  }, [language]);

  useEffect(() => {
    const { edit } = brace;
    const editor = edit(id);
    editor.setOptions({
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      tabSize: 2,
    });
    editor.clearSelection();
  }, [id]);

  useEffect(() => {
    if (!readOnly) return;
    const { edit } = brace;
    const editor = edit(id);
    editor.getSession().setValue(code);
  }, [code]);

  return (
    <div className={cx(styleBase, style)}>
      <ReactAce
        editorId={id}
        mode={parseLanguage[language]}
        setReadOnly={readOnly}
        setValue={code}
        style={{ height: '480px' }}
        theme="tomorrow"
        onChange={onChange}
      />
    </div>
  );
}
