import React from 'react';

import { css } from '@emotion/css';

import DktCard from 'shared/DktCard';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

import picture from 'assets/profile.jpeg';

const cardStyle = css`
  margin: 20px auto;
  background-color: #172B4D;
`;
const pictureStyle = css`
  border-radius: 100%;
  margin-right: 12px;
`;
const flexStyle = css`
  width: auto;
`;
const textStyle = css`
  color: #FFFFFF;
`;

export default function OrganizationBox({ organization }) {
  return (
    <DktCard linkTo={`/organizations/${organization.id}`} style={cardStyle}>
      <FlexLayout alignItems="center" justifyContent="space-between">
        <FlexLayout alignItems="center" style={flexStyle}>
          <img alt="Profile" className={pictureStyle} height={54} src={picture} width={54} />
          <DktText holder="h3" style={textStyle}>{organization.name}</DktText>
        </FlexLayout>
        {/* <DktText holder="h4" style={textStyle}>{`${organization.teamCount} teams`}</DktText> */}
        <FlexLayout alignItems="flex-end" flexDirection="column" style={flexStyle}>
          <DktText holder="h4" style={textStyle}>{`${organization.teacherCount} teachers`}</DktText>
          <DktText holder="h4" style={textStyle}>{`${organization.assistantCount} teaching assist.`}</DktText>
        </FlexLayout>
      </FlexLayout>
    </DktCard>
  );
}
