import React from 'react';

import { css, cx } from '@emotion/css';

const textColor = {
  ACCEPTED: '#008F45',
  COMPILATION_ERROR: '#ED7E40',
  INVALID: '#909090',
  PENDING: '#909090',
  RUNTIME_ERROR: '#ED7E40',
  TIME_LIMIT_EXCEPTION: '#172B4D',
  WRONG_ANSWER: '#C20114',
};

export default function SubmissionStatus({ children, style }) {
  const styleBase = css`
    width: 100%;
    color: ${textColor[children]};
    font-weight: bold;
    font-size: 35px;
    margin: 20px 0;
  `;

  return (
    <p className={cx(styleBase, style)}>{children.replace(/_/g, ' ')}</p>
  );
}
