import React from 'react';

import { css } from '@emotion/css';

import DktCard from 'shared/DktCard';
import DktDivider from 'shared/DktDivider';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

const cardStyle = css`
  margin-top: 8px;
  height: 140px;
`;
const flexStyle = css`
  height: 100%;
`;
const boxStyle = css`
  width: 48%;
  height: 100%;
`;
const inputOutputStyle = css`
  font-weight: 600;
`;
const inputStyle = css`
  box-sizing: content-box;
  border: none;
  font: 16px monospace;
  outline: 0;
  overflow: scroll;
  resize: none;
  white-space: pre;
  width: 100%;
  height: 90%;
`;

export default function RunConsole({ input, output, setInput }) {
  return (
    <>
      <DktText holder="h3">Run Console</DktText>
      <DktCard style={cardStyle}>
        <FlexLayout justifyContent="space-between" style={flexStyle}>
          <div className={boxStyle}>
            <DktText holder="h4" style={inputOutputStyle}>Input</DktText>
            <textarea
              className={inputStyle}
              placeholder="Type your input..."
              value={input}
              onChange={({ target }) => setInput(target.value)}
            />
          </div>
          <DktDivider vertical />
          <div className={boxStyle}>
            <DktText holder="h4" style={inputOutputStyle}>Output</DktText>
            <textarea className={inputStyle} value={output} />
          </div>
        </FlexLayout>
      </DktCard>
    </>
  );
}
