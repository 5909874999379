import React, { useState } from 'react';

import { css } from '@emotion/css';
import { useQuery } from 'react-query';

// import useFilters from 'hooks/useFilters';
import useDikastisRouting, { useQueryParams } from 'router/useDikastisRouting';
import { useAuth } from 'auth/auth-context';
import useMockProblems from 'hooks/mock/useMockProblems';

import DktButton from 'shared/DktButton';
// import DktFilter from 'shared/DktFilter';
import DktSearchBar from 'shared/DktSearchBar';
import FlexLayout from 'shared/FlexLayout';
import PaginationBox from 'shared/PaginationBox';
import ProblemBody from 'problems/shared/ProblemBody';
import ProblemList from 'problems/shared/ProblemList';
import RoundIcons from 'shared/icons/statusIcons/RoundIcons';

const containerStyle = css`
  margin: 80px auto;
  max-width: 1300px;
`;
const searchBarStyle = css`
  margin-bottom: 40px;
`;
const problemListStyle = css`
  width: 80%;
`;
const createProblemStyle = css`
  height: fit-content;
  margin-bottom: 40px;
`;

const ExpandableComponent = ({ row }) => <ProblemBody id={row.original.id} />;

export default function ProblemListPage() {
  const { user: { username } } = useAuth();
  const { path } = useDikastisRouting();
  const authorFilter = path === '/problems/mine' ? `&authorUsername=${username}` : '';
  const queryParams = useQueryParams();
  const pagination = queryParams.page || 1;
  const [search, onSearch] = useState(undefined);
  const searchFilter = search !== undefined && search !== '' ? `&search=${search}` : '';
  const { data: page } = useQuery(`/problems?page=${pagination}${authorFilter}${searchFilter}`);
  const { content } = page;
  const problems = (content || []).map((problem) => ({
    ...problem,
    status: <RoundIcons status={problem.status} />,
  }));
  const { tableColumns } = useMockProblems();
  // const { problemsFilters } = useFilters();

  return (
    <div className={containerStyle}>
      <FlexLayout alignItems="center" flexDirection="column" justifyContent="center">
        <DktButton negative href="/problems/new" style={createProblemStyle}>
          New problem
        </DktButton>
        <DktSearchBar containerStyle={searchBarStyle} placeholder="Search a problem by using regex" onKeyDown={onSearch} />
        <ProblemList
          ExpandableComponent={ExpandableComponent}
          columns={tableColumns}
          problems={problems}
          tableStyle={problemListStyle}
        />
        <PaginationBox currentPage={page.pageNumber} totalPages={page.totalPages} />
      </FlexLayout>
      <FlexLayout alignItems="center" flexDirection="column" justifyContent="center">
        {/* <DktFilter filters={problemsFilters} /> */}
      </FlexLayout>
    </div>
  );
}
