import React from 'react';

import DktDivider from 'shared/DktDivider';

export default function ListWithDivider({ collection, mapFunc }) {
  const isLastElement = (idx) => idx < collection.length - 1;

  return (
    <>
      {collection.map((element, idx) => (
        <div key={element.id}>
          {mapFunc(element, idx)}
          {isLastElement(idx) && <DktDivider />}
        </div>
      ))}
    </>
  );
}
