import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { useField } from 'formik';

import { dikastisApi } from 'dikastis-api';
import { useToast } from 'toast/toast-context';

import DktButton from 'shared/DktButton';
import DktText from 'shared/DktText';
import FlexLayout from 'shared/FlexLayout';

const buttonStyle = css`
  margin-top: 12px;
  width: fit-content;
  height: fit-content;
`;

export default function ProblemValidationForm() {
  const { addToast } = useToast();
  const [{ value: id }] = useField('id');
  const [{ value: valid }] = useField('valid');
  const [validationParams, setValidationParams] = useState([]);

  useEffect(() => {
    dikastisApi.get(`/problems/${id}/validation-params`)
      .then(({ data }) => setValidationParams(data))
      .catch(({ response }) => addToast(response.status));
  }, [valid]);

  const validate = () => {
    dikastisApi.patch(`/problems/${id}`)
      .then(() => addToast(200, 'Problem validated! Save to see changes'))
      .catch(({ response: { status } }) => {
        if (status === 409) {
          addToast(status, 'Check validation parameters');
          return;
        }
        addToast(status);
      });
  };

  return (
    <FlexLayout flexDirection="column">
      <DktText holder="form-title">Validation</DktText>
      <DktText>
        <strong>Valid: </strong>
        {valid.toString()}
      </DktText>
      {validationParams.map((line) => <DktText key={line} holder="p">{`- ${line}`}</DktText>)}
      {!valid && <DktButton negative style={buttonStyle} onClick={validate}>Validate</DktButton>}
    </FlexLayout>
  );
}
