import React from 'react';

import acceptedIcon from 'assets/status-icons/squared/accepted.svg';
import acceptedLateIcon from 'assets/status-icons/squared/accepted-late.svg';
import clockIcon from 'assets/status-icons/squared/clock.svg';
import clockLateIcon from 'assets/status-icons/squared/clock-late.svg';
import excalamationIcon from 'assets/status-icons/squared/exclamation.svg';
import excalamationLateIcon from 'assets/status-icons/squared/exclamation-late.svg';
import redCrossIcon from 'assets/status-icons/squared/red-cross.svg';
import redCrossLateIcon from 'assets/status-icons/squared/red-cross-late.svg';

const statusIcon = {
  ACCEPTED: acceptedIcon,
  ACCEPTED_LATE: acceptedLateIcon,
  COMPILATION_ERROR: excalamationIcon,
  COMPILATION_ERROR_LATE: excalamationLateIcon,
  RUNTIME_ERROR: excalamationIcon,
  RUNTIME_ERROR_LATE: excalamationLateIcon,
  TIME_LIMIT_EXCEPTION: clockIcon,
  TIME_LIMIT_EXCEPTION_LATE: clockLateIcon,
  WRONG_ANSWER: redCrossIcon,
  WRONG_ANSWER_LATE: redCrossLateIcon,
};

export default function SubmissionStatusIcon({ status }) {
  const icon = statusIcon[status] || statusIcon.ACCEPTED;

  return <img alt={status} src={icon} />;
}
